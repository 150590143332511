import * as api from '@/plugins/launcherApi'
import { trueClone } from '@/util'

const state = () => ({
  projects: [],
  companies: [],
  project: {
    ProjectVersions: [],
  },
})

// getters
const getters = {
  projects: state => {
    return state.projects
  },
  companies: state => {
    return state.companies
  },
  project: state => {
    return state.project
  },
}

// actions
const actions = {
  getProjectById({ commit, state }, { projectId }) {
    if (projectId) {
      let response = api.getProjectByIdFromApi(projectId)
      let projectsList = state.projects || []
      let projectIndex = projectsList.findIndex(
        element => element.ID === projectId
      )
      response.ProjectID = projectsList[projectIndex].ID
      response.ProjectName = projectsList[projectIndex].Name
      response.ProjectAppKey = projectsList[projectIndex].AppKey
      commit('setProject', response)
    } else {
      commit('setProject', { ProjectVersions: [] })
    }
  },

  getProjects({ commit, rootState }) {
    const response = api.getProjectsFromApi()
    if (
      rootState.auth.userProfile.access.indexOf('developer') !== -1 ||
      rootState.auth.userProfile.company ||
      rootState.auth.userProfile.projects
    ) {
      if (response.Projects) {
        let projectsList = response.Projects.filter(project => {
          return (
            rootState.auth.userProfile.company === project.Company ||
            (rootState.auth.userProfile.projects &&
              rootState.auth.userProfile.projects.indexOf(project.AppKey) !==
                -1)
          )
        })
        let companiesList = response.Companies
        commit('setProjects', projectsList)
        commit('setCompanies', companiesList)
      }
    } else if (response.Projects) {
      let projectsList = response.Projects
      let companiesList = response.Companies
      commit('setProjects', projectsList)
      commit('setCompanies', companiesList)
    }
  },
}

// mutations
const mutations = {
  setProjects(state, projects) {
    state.projects = projects
  },

  setCompanies(state, companies) {
    state.companies = companies
  },

  setProject(state, project) {
    state.project = trueClone(project)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
