import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

// firebase init
const firebaseConfig = {
  apiKey: 'AIzaSyBDGmMto4Qg3kh53Tsk8Ap_HU0gDFqaR58',
  authDomain: 'say-games-test-tools.firebaseapp.com',
  projectId: 'say-games-test-tools',
  storageBucket: 'say-games-test-tools.appspot.com',
  messagingSenderId: '4578368905',
  appId: '1:4578368905:web:23cc7a640dbade8b799558',
  measurementId: 'G-E9C6FJQQ0G',
}
firebase.initializeApp(firebaseConfig)
// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
// collection references
const usersCollection = db.collection('users')
const gameScreensCollection = db.collection('gameScreens')
const gameMessagesCollection = db.collection('gameMessages')

// export utils/refs
export {
  db,
  auth,
  storage,
  usersCollection,
  gameScreensCollection,
  gameMessagesCollection,
}
