<template>
  <v-container>
    <v-card class="mx-auto" color="gray" width="80vw">
      <v-card-text>
        <div>Select Project And Enter Your IDFA</div>
        <v-row class="text-center">
          <v-col cols="12" md="6" offset-md="3">
            <v-autocomplete
              :items="games"
              label="Select Game"
              :filter="filterGames"
              v-model="game"
              return-object
              clearable
              @change="clearTestData"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.appToken"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-img
                    class="app-icon"
                    :src="
                      `https://www.apptrace.com/api/app/${item.appId}/artwork_url_small`
                    "
                    max-height="24"
                    max-width="24"
                  ></v-img>
                  <v-icon>
                    mdi-{{ item.android ? 'android' : 'apple-ios' }}
                  </v-icon>
                </v-list-item-action>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <v-avatar left max-height="24" max-width="24">
                    <v-img
                      :src="
                        `https://www.apptrace.com/api/app/${item.appId}/artwork_url_small`
                      "
                    />
                  </v-avatar>

                  <span v-text="item.name"></span>
                  <v-avatar right max-height="24" max-width="24">
                    <v-icon>
                      mdi-{{ item.android ? 'android' : 'apple-ios' }}
                    </v-icon>
                  </v-avatar>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" offset-md="3">
            <v-autocomplete
              :items="devicesModel"
              label="Select Device"
              item-text="name"
              return-object
              v-model="selectedDevice"
              @change="clearTestData"
            >
              <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="editMode = !editMode"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>OR Create</span>
                </v-tooltip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.idfa"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <span
                    class="device-text"
                    v-text="`${item.name} (${item.idfa})`"
                  ></span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-expand-transition>
            <v-col
              cols="12"
              md="6"
              offset-md="3"
              v-if="testDeviceData.InstallTime"
            >
              <v-textarea
                label="Test Device Data"
                auto-grow
                disabled
                :value="JSON.stringify(testDeviceData, null, 2)"
              ></v-textarea>
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="primary" @click="findDevice" class="mr-2">
          Check Device
        </v-btn>
        <v-btn
          text
          color="error"
          @click="forgetDevice"
          :disabled="adid === undefined"
        >
          Forget Device
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay absolute :value="editMode">
      <v-card class="mx-auto" color="gray" width="40vw">
        <v-card-text>
          <div>Add device:</div>
          <v-text-field
            ref="deviceName"
            v-model="editedDevice.name"
            label="Device name"
            required
          >
          </v-text-field>
          <v-text-field
            ref="deviceIDFA"
            v-model="editedDevice.idfa"
            :rules="idfaRules"
            label="IDFA"
            required
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="success" @click="addDevice">
            Add
          </v-btn>
          <v-btn text color="secondary" @click="editMode = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AdjustTestingConsole',
  data: () => ({
    game: {},
    editMode: false,
    selectedDevice: { name: '', idfa: '' },
    editedDevice: { name: '', idfa: '' },
    devicesModel: [],
    defaultExpansion: [0],
    idfaRules: [
      v => !!v || 'IDFA is required',
      v =>
        !!/[A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12}$/.test(
          v
        ) || 'IDFA Should looks like: 00000000-0000-0000-0000-000000000000',
    ],
  }),
  watch: {
    devices(newValue) {
      this.devicesModel = [...newValue]
    },
  },
  computed: {
    ...mapState({
      games: state => state.adjustTestingConsole.games,
      testDeviceData: state => state.adjustTestingConsole.testDeviceData,
      adid: state => state.adjustTestingConsole.adid,
    }),
    ...mapGetters('adjustTestingConsole', {
      devices: 'devices',
    }),
  },
  methods: {
    addDevice() {
      if (
        !this.$refs.deviceName.errorBucket.length &&
        !this.$refs.deviceIDFA.errorBucket.length
      ) {
        this.$store.dispatch('adjustTestingConsole/addDevice', {
          device: { ...this.editedDevice },
        })
        this.selectedDevice = Object.assign({}, { ...this.editedDevice })
        this.editedDevice = Object.assign({}, { name: '', idfa: '' })
        this.editMode = !this.editMode
      }
    },
    findDevice() {
      if (!this.selectedDevice || !this.selectedDevice.idfa) {
        this.$store.commit(
          'pushNotification',
          { message: 'Select Device', header: 'Warning:', color: 'warning' },
          { root: true }
        )
      }
      if (!this.game.appToken) {
        this.$store.commit(
          'pushErrorNotification',
          { message: 'Select Game', header: 'Warning:', color: 'warning' },
          { root: true }
        )
      } else {
        this.$store.dispatch('adjustTestingConsole/getTestData', {
          game: this.game,
          device: this.selectedDevice,
        })
      }
    },
    filterGames(item, queryText) {
      const name = item.name.toLowerCase()
      const appToken = item.appToken.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 || appToken.indexOf(searchText) > -1
    },
    clearTestData() {
      this.$store.commit('adjustTestingConsole/setTestDeviceData', {})
      this.$store.commit('adjustTestingConsole/setAdid', undefined)
    },
    forgetDevice() {
      this.$store.dispatch('adjustTestingConsole/forgetDevice', {
        game: this.game,
      })
      this.$store.commit('adjustTestingConsole/setTestDeviceData', {})
    },
  },
  mounted() {
    this.$store.dispatch('adjustTestingConsole/getGames')
    this.devicesModel = [...this.devices]
  },
}
</script>

<style>
.hidden {
  display: none;
}
.app-icon {
  border-radius: 10%;
}
.device-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
}
</style>
