export const getProjectsFromApi = () => {
  return require('@/plugins/mocks/projects.json')
}

export const getProjectByIdFromApi = projectId => {
  console.log('getProjectByIdFromApi', projectId)
  return require('@/plugins/mocks/projectById.json')
}

export const getProjectVersionByIdFromApi = (projectId, projectVersionId) => {
  console.log('getProjectVersionByIdFromApi', projectId, projectVersionId)
  return require('@/plugins/mocks/projectVersionById.json')
}

export const getGameMessagesFromApi = (projectId, projectVersionId) => {
  console.log('getGameMessagesFromApi', projectId, projectVersionId)
  return require('@/plugins/mocks/gameMessages.json')
}

export const getSessions = () => {
  return require('@/plugins/mocks/sessions.json')['sessions']
}
