<template>
  <v-app>
    <Menu />
    <v-main>
      <v-fade-transition>
        <router-view></router-view>
      </v-fade-transition>
      <Notifications />
    </v-main>
  </v-app>
</template>

<script>
import Notifications from '@/components/plugins/Notifications'
import Menu from '@/components/layout/Menu'

export default {
  name: 'App',
  components: {
    Menu,
    Notifications,
  },
}
</script>
