<template>
  <v-container>
    <v-card class="mx-auto login" color="gray" width="60vw">
      <v-card-text>
        <form v-if="showLoginForm" @submit.prevent>
          <div class="text-h4">Welcome Back</div>
          <v-text-field
            v-model.trim="loginForm.email"
            type="email"
            label="Email"
            required
            :rules="emailRules"
          >
          </v-text-field>
          <v-text-field
            v-model.trim="loginForm.password"
            type="password"
            label="Password"
            required
            :rules="passwordRules"
          >
          </v-text-field>
        </form>
        <form v-else @submit.prevent>
          <div class="text-h4">Get Started</div>
          <v-text-field
            v-model.trim="signupForm.email"
            type="email"
            label="Email"
            required
            :rules="emailRules"
          >
          </v-text-field>
          <v-text-field
            v-model.trim="signupForm.password"
            type="password"
            label="Password"
            required
            :rules="passwordRules"
          >
          </v-text-field>
        </form>
      </v-card-text>
      <v-card-actions v-if="showLoginForm">
        <v-btn text color="primary" @click="login">
          Login
        </v-btn>
        <v-btn text color="accent" @click="toggleForm">
          Create an Account
        </v-btn>
        <v-btn text color="error" @click="togglePasswordReset">
          Forgot Password
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn text color="primary" @click="signup">
          Sign Up
        </v-btn>
        <v-btn text color="accent" @click="toggleForm">
          Back to Log In
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay absolute :value="showPasswordReset">
      <PasswordReset
        v-if="showPasswordReset"
        @close="togglePasswordReset()"
      ></PasswordReset>
    </v-overlay>
  </v-container>
</template>

<script>
import PasswordReset from '@/components/PasswordReset'

export default {
  components: {
    PasswordReset,
  },
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      signupForm: {
        email: '',
        password: '',
      },
      showLoginForm: true,
      showPasswordReset: false,
      emailRules: [
        v => !!v || 'Email is required',
        v =>
          !!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Incorrect Email',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v =>
          !!/[\S]{6,20}/g.test(v) ||
          'Password should be at least 6 characters long',
      ],
    }
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset
    },
    login() {
      this.$store.dispatch('auth/login', {
        email: this.loginForm.email,
        password: this.loginForm.password,
      })
    },
    signup() {
      this.$store.dispatch('auth/signup', {
        email: this.signupForm.email,
        password: this.signupForm.password,
      })
    },
  },
}
</script>

<style scoped>
.login {
  margin-top: 25vh;
}
</style>
