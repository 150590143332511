import { trueClone } from '@/util'
import { db, gameMessagesCollection } from '@/plugins/firebase'

const state = () => ({
  gameMessages: [],
  gameMessagesOnScreen: [],
  countNew: 0,
  countUpdated: 0,
  states: ['New', 'Updated', 'Processed', 'Ready', 'Verified', 'Has Issue'],
  isUpdating: false,
  screenNameFilter: '',
})

// getters
const getters = {
  gameMessages: state => {
    return state.gameMessages
  },
}

// actions
const actions = {
  getGameMessagesForCurrentProject({ commit, rootState }) {
    commit('setLoading')
    let projectID = rootState.projects.project.ProjectID
    if (projectID) {
      gameMessagesCollection
        .where('projectID', '==', projectID)
        .get()
        .then(snap => {
          let array = []
          snap.forEach(doc => {
            array.push(doc.data())
          })
          commit('setGameMessages', { gameMessages: array })
          commit('setLoaded')
        })
    } else {
      commit('setGameMessages', [])
      commit('setLoaded')
    }
  },

  getGameMessagesForScreen({ commit, rootState }) {
    let projectID = rootState.projects.project.ProjectID
    let screenName = rootState.gameScreens.screen.name
    if (projectID) {
      gameMessagesCollection
        .where('projectID', '==', projectID)
        .where('screen', 'array-contains', screenName)
        .get()
        .then(snap => {
          let array = []
          snap.forEach(doc => {
            array.push(doc.data())
          })
          commit('setGameMessagesOnScreen', { gameMessages: array })
        })
    } else {
      commit('setGameMessagesOnScreen', [])
    }
  },

  updateMessage({ commit, state }, { message, projectID }) {
    message.prevMessage = state.gameMessages.find(
      item => item.code === message.code
    )
    // First appearing of game message, initial setup
    if (!message.prevMessage) {
      commit('updateNewCounter')
      message.projectID = projectID
      message.state = 'New'
      message.screens = []
      message.revision = 0
      message.limit = 0
    } else {
      message.revision += 1
      // if all languages set
      if (
        message.text_ru.length &&
        message.text_de.length &&
        message.text_fr.length &&
        message.text_es.length &&
        message.text_it.length &&
        message.text_pt.length &&
        message.text_ja.length &&
        message.text_zh.length &&
        message.text_ko.length &&
        message.state !== 'Verified' &&
        message.state !== 'Has Issue'
      ) {
        message.state = 'Ready'
      } else if (
        message.state === 'Verified' ||
        message.state === 'Has Issue'
        // eslint-disable-next-line no-empty
      ) {
      } else if (message.limit && message.screens.length) {
        // if limit is set and screen attached to gameMessage
        message.state = 'Processed'
      }

      // updated if new en text is uploaded
      if (message.prevMessage.text_en !== message.text_en) {
        commit('updateUpdatedCounter')
        message.state = 'Updated'
      }
    }
    message.timestamp = Date.now()
    delete message.prevMessage.prevMessage
  },

  // eslint-disable-next-line no-empty-pattern
  addToBatch({}, { message, batch }) {
    // console.log('addToBatch', message)
    if (!message.code || !message.projectID) {
      console.log(message)
    }
    if (message.prevMessage) {
      batch.update(
        gameMessagesCollection.doc(`${message.code}_${message.projectID}`),
        message
      )
    } else {
      batch.set(
        gameMessagesCollection.doc(`${message.code}_${message.projectID}`),
        message
      )
    }
  },

  uploadBatch({ commit, dispatch, state }, { batches }) {
    batches.forEach((batch, index) => {
      batch
        .commit()
        .then(() => {
          if (index === batches.length - 1) {
            dispatch('getGameMessagesForCurrentProject')
            commit(
              'pushNotification',
              {
                message: `New Strings: ${state.countNew} \nUpdated Strings: ${state.countUpdated}`,
                header: 'Upload Complete!',
                color: 'success',
              },
              { root: true }
            )
          }
        })
        .catch(error => {
          commit('pushErrorNotification', error, { root: true })
        })
    })
  },

  processMessages({ commit, dispatch, state, rootState }, { messages }) {
    let messagesToProcess = messages.map(message => trueClone(message))
    let projectID = rootState.projects.project.ProjectID
    console.log(projectID)
    commit('resetCounter')
    let batches = []
    for (let i = 0; i <= Math.floor(messagesToProcess.length / 300); i++) {
      batches.push(db.batch())
    }

    messagesToProcess.forEach(async (message, index) => {
      message.prevMessage = await state.gameMessages.find(
        item => item.code === message.code
      )
      // First appearing of game message, initial setup
      if (!message.prevMessage) {
        message.prevMessage = null
        commit('updateNewCounter')
        message.projectID = projectID
        message.screens = []
        message.revision = 0
        // if all languages set
        if (
          message.text_ru &&
          message.text_ru.length &&
          message.text_de &&
          message.text_de.length &&
          message.text_fr &&
          message.text_fr.length &&
          message.text_es &&
          message.text_es.length &&
          message.text_it &&
          message.text_it.length &&
          message.text_pt &&
          message.text_pt.length &&
          message.text_ja &&
          message.text_ja.length &&
          message.text_zh &&
          message.text_zh.length &&
          message.text_ko &&
          message.text_ko.length &&
          message.state !== 'Verified' &&
          message.state !== 'Has Issue'
        ) {
          message.state = 'Ready'
          message.limit = [
            message.text_ru.length,
            message.text_de.length,
            message.text_fr.length,
            message.text_es.length,
            message.text_it.length,
            message.text_pt.length,
            message.text_ja.length,
            message.text_zh.length,
            message.text_ko.length,
          ].reduce((max, item) => (max < item ? item : max))
        } else {
          message.limit = 0
          message.state = 'New'
        }
      } else {
        message.projectID = message.prevMessage.projectID
        message.revision += 1
        // if all languages set
        if (
          message.text_ru &&
          message.text_ru.length &&
          message.text_de &&
          message.text_de.length &&
          message.text_fr &&
          message.text_fr.length &&
          message.text_es &&
          message.text_es.length &&
          message.text_it &&
          message.text_it.length &&
          message.text_pt &&
          message.text_pt.length &&
          message.text_ja &&
          message.text_ja.length &&
          message.text_zh &&
          message.text_zh.length &&
          message.text_ko &&
          message.text_ko.length &&
          message.state !== 'Verified' &&
          message.state !== 'Has Issue'
        ) {
          message.state = 'Ready'
          message.limit = [
            message.text_ru.length,
            message.text_de.length,
            message.text_fr.length,
            message.text_es.length,
            message.text_it.length,
            message.text_pt.length,
            message.text_ja.length,
            message.text_zh.length,
            message.text_ko.length,
            message.prevMessage.limit,
          ].reduce((max, item) => (max < item ? item : max))
        } else if (
          message.limit &&
          message.screens.length &&
          message.state !== 'Verified' &&
          message.state !== 'Has Issue'
        ) {
          // if limit is set and screen attached to gameMessage
          message.state = 'Processed'
        }

        // updated if new en text is uploaded
        if (message.prevMessage.text_en !== message.text_en) {
          commit('updateUpdatedCounter')
          message.state = 'Updated'
        }
        delete message.prevMessage.prevMessage
      }
      message.timestamp = Date.now()

      dispatch('addToBatch', {
        message,
        batch: batches[Math.floor(index / 300)],
      })
      if (index === messagesToProcess.length - 1) {
        dispatch('uploadBatch', { batches })
      }
    })
  },

  updateGameMessage({ commit, state }, { gameMessage }) {
    console.log('updateGameMessage.gameMessage', gameMessage)
    gameMessage.prevMessage = state.gameMessages.find(
      item => item.code === gameMessage.code
    )
    // First appearing of game message, initial setup
    gameMessage.revision += 1
    // if all languages set
    if (
      gameMessage.text_ru.length &&
      gameMessage.text_de.length &&
      gameMessage.text_fr.length &&
      gameMessage.text_es.length &&
      gameMessage.text_it.length &&
      gameMessage.text_pt.length &&
      gameMessage.text_ja.length &&
      gameMessage.text_zh.length &&
      gameMessage.text_ko.length &&
      gameMessage.state !== 'Verified' &&
      gameMessage.state !== 'Has Issue'
    ) {
      gameMessage.state = 'Ready'
    } else if (
      gameMessage.limit &&
      gameMessage.screens.length &&
      gameMessage.state !== 'Verified' &&
      gameMessage.state !== 'Has Issue'
    ) {
      // if limit is set and screen attached to gameMessage
      gameMessage.state = 'Processed'
    }

    // updated if new en text is uploaded
    if (gameMessage.prevMessage.text_en !== gameMessage.text_en) {
      commit('updateUpdatedCounter')
      gameMessage.state = 'Updated'
    }
    gameMessage.timestamp = Date.now()
    delete gameMessage.prevMessage.prevMessage
    gameMessagesCollection
      .doc(`${gameMessage.code}_${gameMessage.projectID}`)
      .update({ ...gameMessage })
      .then(() => {
        commit('setGameMessage', { gameMessage })
        commit(
          'pushNotification',
          {
            message: 'Successfully saved!',
            header: 'GameMessage',
            color: 'success',
          },
          { root: true }
        )
      })
      .catch(error => {
        commit('pushErrorNotification', error, { root: true })
      })
  },

  addGameMessageToScreen({ dispatch }, { gameMessage, screenName }) {
    console.log('addGameMessageToScreen.gameMessage', gameMessage)
    console.log('addGameMessageToScreen.screenName', screenName)
    const messageToSave = trueClone(gameMessage)
    messageToSave.screens.push(screenName)
    dispatch('updateGameMessage', { gameMessage: messageToSave })
  },

  removeGameMessageFromScreen({ dispatch }, { gameMessage, screenName }) {
    const messageToSave = trueClone(gameMessage)
    messageToSave.screens = messageToSave.screens || []
    messageToSave.screens = messageToSave.screens.filter(
      screen => screen !== screenName
    )
    dispatch('updateGameMessage', { gameMessage: messageToSave })
  },
}

// mutations
const mutations = {
  resetCounter(state) {
    state.countNew = 0
    state.countUpdated = 0
  },

  updateNewCounter(state) {
    state.countNew += 1
  },

  updateUpdatedCounter(state) {
    state.countUpdated += 1
  },

  setGameMessage(state, { gameMessage }) {
    let index = state.gameMessages.findIndex(
      item => item.code === gameMessage.code
    )
    state.gameMessages[index] = gameMessage
    state.gameMessages = trueClone(state.gameMessages)
  },

  setGameMessages(state, { gameMessages }) {
    console.log('setGameMessages', gameMessages)
    state.gameMessages = gameMessages
  },

  setGameMessagesOnScreen(state, { gameMessages }) {
    console.log('setGameMessagesOnScreen', gameMessages)
    state.gameMessagesOnScreen = gameMessages
  },

  setLoading(state) {
    state.isUpdating = true
  },

  setLoaded(state) {
    state.isUpdating = false
  },

  setScreenNameFilter(state, name) {
    state.screenNameFilter = name
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
