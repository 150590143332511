<template>
  <v-card class="pa-2 ma-2" tile v-if="ads_stats">
    <v-card-title class="card__label">
      Ads Info:
      <v-btn icon @click="isExpanded = !isExpanded">
        <v-icon>
          {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-show="!isExpanded" class="pa-2">
      <div>Total Impressions: {{ calculateTotal('impressions') }}</div>
      <div>Loaded Ads: {{ calculateTotal('loaded') }}</div>
      <div v-if="calculateTotalErrors()" class="error--text">
        Errors: {{ calculateTotalErrors() }}
      </div>
    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="isExpanded">
        <b>Statistics:</b>
        <div :key="`${type}_stats`" v-for="type in adsTypes">
          {{ `${type}:` | capitalize }}:
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ ads_stats[type].impressions }}/{{
                  ads_stats[type].closed
                }}/{{ ads_stats[type].loaded }}/{{ ads_stats[type].clicks }}
              </span>
            </template>
            <span>Impressions / Closed / Loaded / Clicks</span>
          </v-tooltip>
          <v-btn
            icon
            small
            :disabled="!Object.entries(ads_stats[type].networks).length"
            @click="showNetworks[type] = !showNetworks[type]"
          >
            <v-icon>
              {{ showNetworks[type] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
          <v-expand-transition>
            <div
              v-show="showNetworks[type]"
              v-if="Object.entries(ads_stats[type].networks).length"
            >
              <template
                v-for="[network, value] in Object.entries(
                  ads_stats[type].networks
                )"
              >
                <div :key="sessionName + '_interstitials_' + network">
                  <span class="text-sm-caption">
                    {{ network | capitalize }}: {{ value.impressions || 0 }}/{{
                      value.closed || 0
                    }}/{{ value.loaded || 0 }}/{{ value.clicks || 0 }}
                  </span>
                </div>
              </template>
            </div>
          </v-expand-transition>
        </div>
        <v-divider />
        <b>Errors:</b>
        <div :key="`${type}_errors`" v-for="type in adsTypes">
          {{ `${type}:` | capitalize }}:
          <v-btn
            text
            small
            color="error"
            :disabled="!Object.values(ads_stats.errors[type]).length"
            @click="showErrors[type] = !showErrors[type]"
          >
            {{
              Object.values(ads_stats.errors[type]).length
                ? Object.values(ads_stats.errors[type]).reduce((a, b) => a + b)
                : 0
            }}
          </v-btn>
          <v-expand-transition>
            <div v-show="showErrors[type]">
              <div
                v-for="[errorName, quantity] in Object.entries(
                  ads_stats.errors[type]
                )"
                :key="sessionName + errorName"
              >
                <span class="text-sm-caption">{{ errorName }}</span> -
                {{ quantity }}
              </div>
            </div>
          </v-expand-transition>
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { trueClone } from '../../../util'

export default {
  name: 'AdsStats',

  props: ['ads_stats', 'isExpanded', 'sessionName'],

  data() {
    return {
      adsTypes: ['interstitial', 'rewarded', 'banner'],
      showNetworks: {
        interstitial: false,
        rewarded: false,
        banner: false,
      },
      showErrors: {
        interstitial: false,
        rewarded: false,
        banner: false,
      },
    }
  },

  filters: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  methods: {
    calculateTotal(type) {
      let ads = trueClone(this.ads_stats)
      delete ads.errors
      ads = Object.values(ads).map(val => val[type])
      return ads.length ? ads.reduce((a, b) => a + b) : 0
    },
    calculateTotalErrors() {
      let errorsCount = 0
      Object.values(this.ads_stats.errors).forEach(value => {
        errorsCount += Object.values(value).length
          ? Object.values(value).reduce((a, b) => a + b)
          : 0
      })
      return errorsCount
    },
  },
}
</script>

<style scoped>
.card__label {
  position: absolute;
  top: -36px;
  left: -8px;
}
</style>
