<template>
  <v-card class="pa-2 ma-2" tile v-if="status">
    <v-card-title class="card__label">
      Session Status:
      <v-btn icon @click="isExpanded = !isExpanded">
        <v-icon>
          {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-show="!isExpanded" class="pa-2">
      <v-chip>{{ status.version }}</v-chip>
      <v-chip>{{ status.mediation }}</v-chip>
      <v-chip>{{ status.attribution | capitalize }}</v-chip>
      <v-chip>{{ status.saykit_version }}</v-chip>
    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="isExpanded">
        <div>
          Mediation: <span>{{ status.mediation }}</span>
        </div>
        <div>
          Attribution:
          {{ status.attribution | capitalize }}
        </div>
        <div>SayKit Version: {{ status.saykit_version }}</div>
        <div>Language: {{ status.language.toUpperCase() }}</div>
        <div>Unity Version: {{ status.unity_version }}</div>
        <div>SKAD Status: {{ status.skad_status }}</div>
        <div>SKAD Changed: {{ status.skad_status_changed }}</div>
        <div>Version: {{ status.version }}</div>
        <div>Build: {{ status.build }}</div>
        <div>
          Monetizations:
          <v-btn icon @click="showMonetizations = !showMonetizations">
            <v-icon>
              {{ showMonetizations ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
          <v-expand-transition>
            <div v-show="showMonetizations">
              <template v-for="sdk in status.sdk_monetizations">
                <div :key="sessionName + sdk.name">
                  {{ sdk.name.split('_')[1] | capitalize }}:
                  {{ sdk.value }}
                </div>
              </template>
            </div>
          </v-expand-transition>
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'Status',

  props: ['status', 'isExpanded', 'sessionName'],

  data() {
    return {
      showMonetizations: false,
    }
  },

  filters: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
}
</script>

<style scoped>
.card__label {
  position: absolute;
  top: -36px;
  left: -8px;
}
</style>
