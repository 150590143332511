<template>
  <v-card class="pa-2 ma-2" tile v-if="gameStats">
    <v-card-title class="card__label">
      Game Stats:
      <v-btn icon @click="isExpanded = !isExpanded">
        <v-icon>
          {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-show="!isExpanded" class="pa-2">
      <div>
        Level: {{ gameStats.level.initial }}/{{ gameStats.level.final }}
      </div>
      <div>
        Soft: {{ gameStats.scurrency.initial || 0 }}/{{
          gameStats.scurrency.final || 0
        }}, Hard: {{ gameStats.hcurrency.initial || 0 }}/{{
          gameStats.hcurrency.final || 0
        }}
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              FPS: {{ gameStats.fps.min }}/{{ gameStats.fps.max }}/{{
                gameStats.fps.avg
              }}
            </span>
          </template>
          <span>Min/Max/Avg</span>
        </v-tooltip>
      </div>
    </v-card-text>
    <v-expand-transition>
      <v-list v-show="isExpanded" dense>
        <v-list-group
          :key="`${type}_gameStats`"
          v-for="type in statsTypes"
          :value="true"
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-title>
              {{ `${type}:` | capitalize }}
            </v-list-item-title>
          </template>

          <v-list-item link v-if="gameStats[type].initial">
            <v-list-item-title>Initial:</v-list-item-title>
            <v-list-item-action>
              {{ gameStats[type].initial }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item link v-if="gameStats[type].final">
            <v-list-item-title>Final:</v-list-item-title>
            <v-list-item-action>
              {{ gameStats[type].final }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            dense
            v-if="
              gameStats[type].min &&
                gameStats[type].initial !== gameStats[type].min
            "
          >
            <v-list-item-title>Min:</v-list-item-title>
            <v-list-item-action
              :class="{ 'error--text': gameStats[type].min <= 20 }"
            >
              {{ gameStats[type].min }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            link
            v-if="
              gameStats[type].max &&
                gameStats[type].final !== gameStats[type].max
            "
          >
            <v-list-item-title>Max:</v-list-item-title>
            <v-list-item-action
              :class="{ 'error--text': gameStats[type].max <= 20 }"
            >
              {{ gameStats[type].max }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item link v-if="gameStats[type].gain">
            <v-list-item-title class="green--text">Gain:</v-list-item-title>
            <v-list-item-action>
              {{ gameStats[type].gain }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item link v-if="gameStats[type].spent" color="warning">
            <v-list-item-title class="error--text">Spent:</v-list-item-title>
            <v-list-item-action>
              {{ gameStats[type].spent }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item link v-if="gameStats[type].avg">
            <v-list-item-title>Avg:</v-list-item-title>
            <v-list-item-action>
              {{ gameStats[type].avg }}
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'GameStats',

  props: ['gameStats', 'isExpanded', 'sessionName'],

  data() {
    return {
      statsTypes: ['level', 'scurrency', 'hcurrency', 'fps'],
    }
  },

  filters: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
}
</script>

<style scoped>
.card__label {
  position: absolute;
  top: -36px;
  left: -8px;
}
</style>
