import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from '@/plugins/logger'
import auth from '@/store/modules/auth'
import notifications from '@/store/modules/notifications'
import playableLink from '@/store/modules/playableLink'
import playablePage from '@/store/modules/playablePage'
import adjustTestingConsole from '@/store/modules/adjustTestingConsole'
import projects from '@/store/modules/projects'
import gameScreens from '@/store/modules/gameScreens'
import gameMessages from '@/store/modules/gameMessages'
import languages from '@/store/modules/languages'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    notifications,
    playableLink,
    playablePage,
    adjustTestingConsole,
    projects,
    gameScreens,
    gameMessages,
    languages,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})
