import * as fb from '@/plugins/firebase'
import router from '@/router/index'

const state = () => ({
  userProfile: JSON.parse(localStorage.getItem('userProfile')) || {
    access: [],
  },
})

const mutations = {
  setUserProfile(state, val) {
    //TODO: fix auth roles from temporary saving roles in local storage
    state.userProfile = val
    localStorage.setItem('userProfile', JSON.stringify(state.userProfile))
  },
  setPerformingRequest(state, val) {
    state.performingRequest = val
  },
}

const getters = {
  userProfile: state => {
    return state.userProfile
  },
}

const actions = {
  async login({ commit, dispatch }, form) {
    // sign user in
    try {
      const { user } = await fb.auth.signInWithEmailAndPassword(
        form.email,
        form.password
      )

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    } catch (error) {
      commit('pushErrorNotification', error, { root: true })
    }
  },
  async signup({ commit, dispatch }, form) {
    // sign user up
    try {
      const { user } = await fb.auth.createUserWithEmailAndPassword(
        form.email,
        form.password
      )

      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        email: form.email,
        access: [],
      })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    } catch (error) {
      commit('pushErrorNotification', error, { root: true })
    }
  },

  async fetchUserProfile({ commit }, user) {
    // fetch user profile
    const userProfile = await fb.usersCollection.doc(user.uid).get()

    // set user profile in state
    commit('setUserProfile', userProfile.data())

    // change route to dashboard
    if (router.currentRoute.path === '/login') {
      if (userProfile.data().access && userProfile.data().access.length === 0) {
        router.push('/playables')
      } else if (
        userProfile.data().access &&
        userProfile.data().access.includes('adjust')
      ) {
        router.push('/adjust')
      } else {
        router.push('/')
      }
    }
  },
  async logout({ commit }) {
    // log user out
    await fb.auth.signOut()

    // clear user data from state
    commit('setUserProfile', { access: [] })

    // redirect to login view
    router.push('/login')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
