<template>
  <v-main>
    <v-row>
      <template v-for="routeObject in $router.getRoutes()">
        <v-col cols="12" offset-sm="5" :key="routeObject.name">
          <router-link
            v-if="!routeObject.meta.hidden"
            :to="routeObject.path"
            v-slot="{ href, route, navigate, isActive }"
          >
            <a>
              <v-btn
                :active="isActive"
                :href="href"
                @click="navigate"
                class="ma-1"
                plain
              >
                <v-icon left>{{ route.meta.icon }}</v-icon>
                {{ route.name }}
              </v-btn>
            </a>
          </router-link>
        </v-col>
      </template>
    </v-row>
  </v-main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  mounted() {
    console.log(this.$router.options)
  },
}
</script>
