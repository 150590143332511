const state = () => ({
  pushID: 0,
  messages: [],
})

// getters
const getters = {
  messages: state => {
    return state.messages
  },
}

// mutations
const mutations = {
  pushTextErrorNotification(state, error) {
    state.pushID += 1
    state.messages.push({
      pushID: state.pushID,
      message: error,
      header: 'Error',
      color: 'error',
      timeout: 5000,
    })
  },

  pushErrorNotification(state, error) {
    let message = ''
    if (error.message) {
      console.error(error)
      message = error.message
    }
    if (error.response) {
      message = error.response.data
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    }
    state.pushID += 1
    state.messages.push({
      pushID: state.pushID,
      header: 'Error',
      message: message,
      color: 'error',
      timeout: 5000,
    })
  },

  pushNotification(state, notification) {
    console.log('notification', notification)
    notification.color = notification.color || 'info'
    notification.timeout = notification.timeout || 5000
    state.pushID += 1

    if (notification.message) {
      state.messages.push({
        pushID: state.pushID,
        ...notification,
      })
    }
  },

  popNotification(state, messageToDelete) {
    state.messages = state.messages.filter(
      message => message.pushID !== messageToDelete.pushID
    )
  },
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
}
