<template>
  <div>
    <!--    <v-btn dark color="green" class="mt-6 ml-3" @click="addObject()">-->
    <!--      Random Toast-->
    <!--    </v-btn>-->
    <v-snackbars :objects.sync="objects" top right>
      <template v-slot:default="{ message }">
        <h3 class="mb-2">{{ getHeader(message) }}</h3>
        {{ message }}
      </template>
      <template v-slot:action="{ close }">
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbars>
  </div>
</template>

<script>
import VSnackbars from 'v-snackbars'
import { mapState } from 'vuex'
import { trueClone } from '@/util'

export default {
  name: 'Notifications',
  components: {
    'v-snackbars': VSnackbars,
  },

  data() {
    return {
      lastShownPush: 0,
      indexText: 0,
      randomText: [
        'Lorem ipsum dolor sit amet',
        'this is a very long text to show how the toast will handle this special case when the content is very long and shows on more than 1 line',
        'consectetur adipiscing elit',
        'sed do eiusmod tempor',
        'incididunt ut labore et dolore',
        'magna aliqua',
        'Turpis massa tincidunt',
        'dui ut ornare',
        'Tempor nec feugiat nisl',
        'pretium fusce id velit',
        'At imperdiet dui accumsan sit',
        'Id volutpat lacus',
      ],
      colors: ['error', 'info', 'success', 'warning'],
      transitions: ['fab-transition', 'scale-transition', 'fade-transition'],
      objects: [],
    }
  },

  computed: {
    ...mapState({
      messages: state => state.notifications.messages,
      pushID: state => state.notifications.pushID,
    }),
  },

  watch: {
    objects(newValue, oldValue) {
      const itemsToRemove = oldValue.filter(item => !newValue.includes(item))
      itemsToRemove.forEach(item =>
        this.$store.commit('popNotification', item, { root: true })
      )
    },

    messages(newValue) {
      if (
        newValue.length &&
        newValue[newValue.length - 1].pushID > this.lastShownPush
      ) {
        this.lastShownPush = this.pushID
        this.objects.push(trueClone(newValue[newValue.length - 1]))
      }
    },
  },

  methods: {
    getHeader(message) {
      return this.objects.find(notification => notification.message === message)
        .header
    },

    addObject() {
      if (this.indexText >= this.randomText.length) this.indexText = 0
      let randomColor = Math.floor(Math.random() * 11)
      this.$store.commit(
        'pushNotification',
        {
          message: this.randomText[this.indexText++],
          header: this.randomText[this.indexText++],
          color: this.colors[randomColor],
          timeout: 5000,
        },
        { root: true }
      )
    },
    timeout(delay) {
      return new Promise(res => {
        setTimeout(res, delay * 1000)
      })
    },
  },
}
</script>

<style scoped></style>
