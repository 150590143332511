const axios = require('axios')

export default {
  getPlayable(htmlUrl) {
    return new Promise((resolve, reject) => {
      axios
        .get(htmlUrl)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateLocalization({ playableID, localizationPayload, spVarsType }) {
    return new Promise((resolve, reject) => {
      let data = {}
      if (spVarsType === 'string') {
        data.localization = JSON.stringify(localizationPayload)
      } else if (spVarsType === 'json') {
        data.localization = localizationPayload
      }
      let config = {
        headers: {
          'api-key': '0hE5PJSEaCoybiJUVFHyPgL1',
        },
        json: true,
      }
      axios
        .post(
          `https://playables.saygames.io/api/playable/localization/${playableID}`,
          data,
          config
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
