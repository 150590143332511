<template>
  <div class="home">
    <AdjustTestingConsole />
  </div>
</template>

<script>
import AdjustTestingConsole from '@/components/AdjustTestingConsole'

export default {
  name: 'Adjust',
  components: {
    AdjustTestingConsole,
  },
}
</script>
