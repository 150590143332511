const languagesList = [
  { text: 'English', short_name: 'En', value: 'text_en' },
  { text: 'Russian', short_name: 'Ru', value: 'text_ru' },
  { text: 'German', short_name: 'De', value: 'text_de' },
  { text: 'French', short_name: 'Fr', value: 'text_fr' },
  { text: 'Spanish', short_name: 'Sp', value: 'text_es' },
  { text: 'Italian', short_name: 'It', value: 'text_it' },
  { text: 'Portuguese', short_name: 'Pt', value: 'text_pt' },
  { text: 'Japan', short_name: 'Ja', value: 'text_ja' },
  { text: 'Chinese', short_name: 'Zh', value: 'text_zh' },
  { text: 'Korean', short_name: 'Ko', value: 'text_ko' },
  // { text: 'Arabic', short_name: 'Ar', value: 'text_ar' },
  // { text: 'Hindi', short_name: 'Hi', value: 'text_hi' },
]

const state = () => ({
  allLanguages: languagesList,
  selectedLanguages:
    JSON.parse(localStorage.getItem('languages')) || languagesList,
})

// getters
const getters = {
  allLanguages: state => {
    return state.allLanguages
  },
  selectedLanguages: state => {
    return state.selectedLanguages
  },
}

// actions
const actions = {}

// mutations
const mutations = {
  setSelectedLanguages(state, languages) {
    localStorage.setItem('languages', JSON.stringify(languages))
    state.selectedLanguages = languages
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
