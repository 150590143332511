import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/plugins/firebase'
import store from '@/store'

import Home from '@/views/Home.vue'
import Login from '@/views/Login'
import Adjust from '@/views/Adjust'
import Localizations from '@/views/Localizations'
import Playables from '@/views/Playables.vue'
import DebugView from '@/views/DebugView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      hidden: true,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      hidden: true,
      requiresAuth: true,
    },
  },
  {
    path: '/playables',
    name: 'Playables',
    component: Playables,
    meta: {
      icon: 'mdi-gamepad-square',
      requiresAuth: true,
    },
  },
  {
    path: '/adjust',
    name: 'Adjust',
    meta: {
      icon: 'mdi-google-ads',
      requiresAuth: true,
      requiredAccess: ['adjust', 'admin'],
    },
    component: Adjust,
  },
  {
    path: '/localizations',
    name: 'Localizations',
    meta: {
      icon: 'mdi-translate',
      requiresAuth: true,
      requiredAccess: ['localizations', 'admin'],
    },
    component: Localizations,
  },
  {
    path: '/debug-view',
    name: 'Debug View',
    meta: {
      icon: 'mdi-cellphone-arrow-down',
      requiresAuth: true,
      requiredAccess: ['debugView', 'admin'],
    },
    component: DebugView,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiredAccess = to.meta.requiredAccess
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }

  if (requiredAccess) {
    const userProfile = store.state.auth.userProfile
    // console.log(
    //   requiredAccess,
    //   userProfile.access,
    //   checkPermission(userProfile.access, requiredAccess)
    // )
    let checkPermission = (arr, target) => target.some(v => arr.includes(v))
    if (checkPermission(userProfile.access, requiredAccess)) {
      console.log('next')
      next()
    } else {
      store.commit(
        'pushNotification',
        {
          message:
            "You don't have permissions to see this page, contact administrator",
          header: 'Access Denied!',
          color: 'error',
        },
        { root: true }
      )
      console.log('reset')
      next('/')
    }
  } else {
    console.log('next')
    next()
  }
})

export default router
