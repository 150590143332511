const state = () => ({
  linkMatcher: /.*playables\.saygames\.io\/(playable|client\/playables)(?:(\/show))?\/([a-zA-Z0-9]*)$/,
  url: '',
  playableID: '',
  htmlUrl: '',
  isCorrectLink: false,
})

// getters
const getters = {
  playableID: state => {
    return state.playableID
  },

  url: state => {
    return state.url
  },

  htmlUrl: state => {
    return state.htmlUrl
  },

  isCorrectLink: state => {
    return state.isCorrectLink
  },

  linkMatcher: state => {
    return state.linkMatcher
  },
}

// actions
const actions = {
  parseLink({ commit, state }, { link }) {
    let result
    if ((result = state.linkMatcher.exec(link)) !== null) {
      result.forEach((match, groupIndex) => {
        // console.log(`Found match, group ${groupIndex}: ${match}`)
        if (groupIndex === 3) {
          commit('setPlayableID', { id: match })
          commit('setUrl', { link })
          commit('setPlayableHTMLUrl', { id: match })
          commit('setIsCorrectLink', true)
        }
      })
    } else {
      commit('setIsCorrectLink', false)
    }
  },
}

// mutations
const mutations = {
  setPlayableID(state, { id }) {
    state.playableID = id
  },

  setUrl(state, { link }) {
    state.url = link
  },

  setPlayableHTMLUrl(state, { id }) {
    state.htmlUrl = `https://playables.saygames.io/share/${id}/test.html`
  },

  setPlayableLocalizedUrl(state, { locale }) {
    console.log(locale)
    state.htmlUrl = `https://playables.saygames.io/share/${state.playableID}/test.html?language=${locale}`
  },

  setIsCorrectLink(state, isCorrect) {
    state.isCorrectLink = isCorrect
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
