import api from '@/api/adjustApi'

const state = () => ({
  games: [],
  devices: JSON.parse(localStorage.getItem('devices')) || [],
  appToken: undefined,
  adid: undefined,
  testDeviceData: {},
})

// getters
const getters = {
  games: state => {
    return state.games
  },
  devices: state => {
    return state.devices
  },
  appToken: state => {
    return state.appToken
  },
  adid: state => {
    return state.adid
  },
  testDeviceData: state => {
    return state.testDeviceData
  },
}

// actions
const actions = {
  addDevice({ commit, state }, { device }) {
    let devicesList = state.devices || []
    if (devicesList.findIndex(element => element.idfa === device.idfa) === -1) {
      devicesList.push(device)
    }
    commit('setDevices', devicesList)
  },

  getGames({ commit }) {
    api
      .getApps()
      .then(async response => {
        let appsList = response.data
        let gamesList = []
        await appsList.apps.forEach(app => {
          gamesList.push({
            appToken: app.app_token,
            name: app.name,
            id: app.id,
            android: app.platforms.android.configured,
            ios: app.platforms.ios.configured,
            appId: app.platforms.android.app_id || app.platforms.ios.app_id,
          })
        })
        await gamesList.sort((item1, item2) => {
          return item1.name.localeCompare(item2.name)
        })
        commit('setGames', gamesList)
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          commit(
            'pushNotification',
            {
              message: error.response.data,
              header: "Can't get list of applications",
              color: 'error',
            },
            { root: true }
          )
        } else {
          commit('pushErrorNotification', error, { root: true })
        }
      })
  },
  getTestData({ commit }, { game, device }) {
    commit('setAppToken', game.appToken)
    commit('setAdid', undefined)
    api
      .getDeviceData({ app_token: game.appToken, idfa: device.idfa })
      .then(response => {
        let respData = response.data
        commit('setTestDeviceData', respData)
        if (respData.Adid) {
          commit('setAdid', respData.Adid)
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          commit(
            'pushNotification',
            {
              message: 'No data found in adjust for this device',
              header: 'Not found',
              color: 'info',
            },
            { root: true }
          )
        } else {
          commit('pushErrorNotification', error, { root: true })
        }
      })
  },

  forgetDevice({ commit, state }, { game }) {
    api
      .forgetDevice({ app_token: game.appToken, adid: state.adid })
      .then(() => {
        commit(
          'pushNotification',
          {
            message: 'Device successfully forgotten',
            header: 'Success',
            color: 'success',
          },
          { root: true }
        )
        commit('setAdid', undefined)
      })
      .catch(error => {
        commit('pushErrorNotification', error, { root: true })
      })
  },
}

// mutations
const mutations = {
  setGames(state, games) {
    state.games = games
  },

  setDevices(state, devicesList) {
    localStorage.setItem('devices', JSON.stringify(devicesList))
    state.devices = devicesList
  },

  setAppToken(state, appToken) {
    state.appToken = appToken
  },

  setAdid(state, adid) {
    state.adid = adid
  },

  setTestDeviceData(state, deviceData) {
    state.testDeviceData = deviceData
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
