<template>
  <v-card class="mt-1" v-if="toggleImages">
    <v-card-text>
      <div>Game Screens</div>
      <v-row class="text-center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <div v-if="!file" class="dropZone-container">
            <div
              :class="['dropZone', dragging ? 'dropZone-over' : '']"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
            >
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title">
                  Drop new game screens here
                </span>
              </div>
              <input
                type="file"
                multiple
                @change="onChange"
                :disabled="project && !project.ProjectID"
              />
            </div>
          </div>
          <div v-else class="dropZone-uploaded">
            <div class="dropZone-uploaded-info">
              <span class="dropZone-title">Uploaded</span>
              <button
                type="button"
                class="btn btn-primary removeFile"
                @click="removeFile"
              >
                Remove File(s)
              </button>
            </div>
          </div>
        </v-col>
      </v-row>
      <GameScreen
        :key="keyChanger"
        v-if="selectedScreen"
        v-on:process-screen="processScreen"
      />
      <v-slide-group
        v-model="selectedScreen"
        class="pa-4"
        active-class="success"
        mandatory
      >
        <v-slide-item
          v-for="screen in allScreens"
          :key="screen.name"
          v-slot="{ active, toggle }"
          :value="screen"
        >
          <v-card
            :color="active ? undefined : 'grey lighten-1'"
            class="ma-4"
            height="15vh"
            width="7vw"
            @click="toggle"
          >
            <v-img max-height="100%" :src="screen.imageUrl"></v-img>
            <v-row
              class="fill-height image-overlay"
              align="center"
              justify="center"
            >
              <v-scale-transition>
                <v-icon
                  v-if="active"
                  color="white"
                  size="48"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-scale-transition>
            </v-row>
            <v-row
              class="fill-height image-info"
              align="center"
              justify="center"
              v-if="screen.file"
            >
              <v-icon
                color="info"
                size="36"
                v-text="'mdi-new-box'"
                class="image-state"
              ></v-icon>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import GameScreen from '@/components/GameScreen'
import { trueClone } from '@/util'

export default {
  name: 'GamesScreensView',
  props: ['toggleImages', 'changedScreen'],
  components: {
    GameScreen,
  },
  data: () => ({
    newScreens: [],
    selectedScreen: {},
    file: undefined,
    dragging: false,
    loader: null,
    downloadLoading: false,
    uploadLoading: false,
    search: '',
    keyChanger: 0,
  }),
  watch: {
    selectedScreen(newValue) {
      this.$store.commit('gameScreens/setGameScreen', newValue)
    },
    project(newValue) {
      this.selectedScreen = trueClone({})
      this.keyChanger += 1
      if (newValue) {
        this.$store.dispatch('gameScreens/getGameScreens', {
          projectID: newValue.ProjectID,
        })
      }
    },
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      gameMessages: state => state.gameMessages.gameMessages,
      gameScreens: state => state.gameScreens.screens,
    }),
    allScreens() {
      return this.gameScreens.concat(this.newScreens)
    },
  },
  methods: {
    selectFile(file) {
      this.currentFile = file
    },
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        this.dragging = false
        return
      } else {
        for (let index in Array.from(files)) {
          this.createFile(files[index])
        }
      }
    },
    createFile(file) {
      if (file.size > 25000000) {
        this.$store.commit(
          'pushTextErrorNotification',
          'File size limit: 25MB.',
          { root: true }
        )
        this.dragging = false
        return
      }

      if (file.constructor.name === 'File') {
        this.prepareUploadImage(file)
      }
      this.dragging = false
    },
    removeFile() {
      this.file = ''
    },
    downloadCSV() {
      //qwe
    },
    expandImage(image) {
      // console.log(image, this.expanded)
      // console.log(this.expanded.findIndex(element => element.downloadURL === image.downloadURL))
      if (this.expanded.length) {
        if (
          this.expanded.findIndex(
            element => element.downloadURL === image.downloadURL
          ) !== -1
        ) {
          this.expanded.pop()
        } else {
          // this.expanded.pop()
          this.expanded.push(image)
        }
      } else {
        this.expanded.push(image)
      }
    },
    prepareUploadImage(file) {
      // eslint-disable-next-line no-unused-vars
      this.loader = 'uploadLoading'
      const reader = new FileReader()
      reader.addEventListener('load', event => {
        const imageUrl = event.target.result
        this.newScreens.push({ imageUrl: imageUrl, file, key: Date.now() })
      })
      reader.readAsDataURL(file)
    },
    processScreen(editedScreen) {
      if (this.newScreens.length) {
        this.newScreens = this.newScreens.filter(screen => {
          return screen.key !== editedScreen.key
        })
        if (this.newScreens.length === 0) {
          this.$store.commit(
            'pushNotification',
            {
              message: 'All new screens has been processed!',
              header: 'Success:',
              color: 'success',
            },
            { root: true }
          )
          this.$store.dispatch('gameScreens/getGameScreens', {
            projectID: this.project.ProjectID,
          })
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('projects/getProjects')
    if (this.project) {
      this.$store.dispatch('gameScreens/getGameScreens', {})
    }
  },
}
</script>

<style scoped>
.hidden {
  display: none;
}

.dropZone-container {
  margin-top: 15px !important;
  min-width: 360px !important;
}

.dropZone {
  width: 100%;
  height: 36px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-uploaded {
  width: 100%;
  min-width: 360px !important;
  height: 36px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #a8a8a8;
  position: relative;
  top: 50%;
  width: 100%;
  transform: translate(30%, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}

.image-overlay {
  transform: translate(0%, -90%);
}
.image-state {
  position: absolute;
  transform: translate(85%, -130%);
}

.image-info {
  transform: translate(0%, -190%);
}
</style>
