import api from '@/api/api'

const state = () => ({
  localizationRegExp: /var spVars = (.*);/g,
  // localizationEncodedRegExp: /var spVars = JSON.parse\(decodeURIComponent\((.*)\)\);/g,
  pageContent: '',
  locale: '',
  localizationPayload: '',
  spVarsType: 'string',
  isLocaleSet: false,
  isLocaleUpdated: false,
  locales: [
    {
      code: 'en',
      codeFull: 'en-US',
      displayName: 'English',
    },
    {
      code: 'ru',
      codeFull: 'ru-RU',
      displayName: 'Russian',
    },
    {
      code: 'de',
      codeFull: 'de-DE',
      displayName: 'German',
    },
    {
      code: 'fr',
      codeFull: 'fr-FR',
      displayName: 'French',
    },
    {
      code: 'es',
      codeFull: 'es-ES',
      displayName: 'Spanish',
    },
    {
      code: 'it',
      codeFull: 'it-IT',
      displayName: 'Italian',
    },
    {
      code: 'pt',
      codeFull: 'pt-BR',
      displayName: 'Portuguese (Br)',
    },
    {
      code: 'ja',
      codeFull: 'ja-JA',
      displayName: 'Japanese',
    },
    {
      code: 'zh',
      codeFull: 'zh-SP',
      displayName: 'Chinese (Simplified)',
    },
    {
      code: 'ko',
      codeFull: 'ko-KO',
      displayName: 'Korean',
    },
  ],
})

// getters
const getters = {
  pageContent: state => {
    return state.pageContent
  },

  locale: state => {
    return state.locale
  },

  localizationPayload: state => {
    return state.localizationPayload
  },

  isLocaleSet: state => {
    return state.isLocaleSet
  },

  spVarsType: state => {
    return state.spVarsType
  },
}

// actions
const actions = {
  getPage({ commit, state }, { url }) {
    api
      .getPlayable(url)
      .then(async response => {
        let pageContent = response.data
        let result

        if ((result = state.localizationRegExp.exec(response.data)) !== null) {
          result.forEach((match, groupIndex) => {
            // console.log(`Found match, group ${groupIndex}: ${match}`)
            let spVars = ''
            if (groupIndex === 1) {
              if (match.includes('JSON.parse')) {
                spVars = eval(match)
              } else {
                spVars = JSON.parse(match)
              }
              if (
                spVars.localization !== undefined &&
                spVars.localization.value !== undefined
              ) {
                console.log(spVars)
                let localization = spVars.localization.value
                if (typeof spVars.localization.value === 'object') {
                  commit('setSPVarsType', { type: 'json' })
                } else if (typeof spVars.localization.value === 'string') {
                  commit('setSPVarsType', { type: 'string' })
                  localization = JSON.parse(spVars.localization.value)
                }
                commit('setLocalizationPayload', { payload: localization })
              } else {
                commit(
                  'pushTextErrorNotification',
                  'SPVARS.LOCALIZATION PAYLOAD IS NOT FOUND',
                  { root: true }
                )
                // console.error('SPVARS.LOCALIZATION PAYLOAD IS NOT FOUND')
              }
            }
          })
        } else {
          commit('pushTextErrorNotification', 'SPVARS PAYLOAD IS NOT FOUND', {
            root: true,
          })
          // console.error('SPVARS PAYLOAD IS NOT FOUND')
        }

        //manually resetting regexp for future use
        state.localizationRegExp.lastIndex = 0
        // state.localizationEncodedRegExp.lastIndex = 0
        commit('setPageContent', { pageContent: pageContent })
      })
      .catch(error => {
        commit('pushErrorNotification', error, { root: true })
      })
  },
}

// mutations
const mutations = {
  setPageContent(state, { pageContent }) {
    state.pageContent = pageContent
  },

  setLocale(state, locale) {
    state.locale = locale
  },

  setLocalizationPayload(state, { payload }) {
    state.localizationPayload = payload
  },

  setSPVarsType(state, { type }) {
    state.spVarsType = type
  },

  setIsLocaleSet(state, isLocaleSet) {
    state.isLocaleSet = isLocaleSet
  },

  base64toArray(base64){
    for(var n=atob(base64.substring(base64.indexOf(",")+1)),e=n.length,d=new Uint8Array(e);e--;)
      d[e]=n.charCodeAt(e)
    return d
  },
  convertBase64(res){
    return res=(res=res.replace(/-/g,"+")).replace(/_/g,"/")
  },

  base64toArrayBuffer(base64) {
    var bstr = atob(base64.substring(base64.indexOf(",") + 1)),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    console.log(u8arr.buffer);
}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
