<template>
  <v-card class="pa-2 ma-2" tile v-if="errors">
    <v-card-title class="card__label">
      Errors:
      <v-btn icon @click="isExpanded = !isExpanded">
        <v-icon>
          {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-show="!isExpanded" class="pa-2">
      <div :class="{ 'error--text': calculateTotalExceptions() > 0 }">
        Exceptions: {{ calculateTotalExceptions() }}
      </div>
      <div :class="{ 'error--text': errors.crashes.length > 0 }">
        Crashes: {{ errors.crashes.length || 0 }}
      </div>
    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="isExpanded">
        <div v-if="calculateTotalExceptions() > 0">
          <div>
            Exceptions:
            <div
              :key="sessionName + type"
              v-for="[type, values] in Object.entries(errors.exceptions)"
            >
              <v-btn
                text
                small
                color="error"
                :disabled="!Object.keys(values).length"
                @click="showExceptions = !showExceptions"
              >
                {{ type | capitalize }}:
                {{
                  Object.values(values).length
                    ? Object.values(values)
                        .map(exc => exc.quantity)
                        .reduce((a, b) => a + b.quantity)
                    : 0
                }}
              </v-btn>
              <v-expand-transition>
                <div v-show="showExceptions">
                  <div
                    v-for="[key, exception] in Object.entries(values)"
                    :key="sessionName + key"
                  >
                    {{ exception.value }}: {{ exception.quantity }}
                  </div>
                </div>
              </v-expand-transition>
            </div>
          </div>
          <div>
            Crashes:
            <v-btn
              text
              small
              color="error"
              :disabled="!errors.crashes.length"
              @click="showCrashes = !showCrashes"
            >
              {{ errors.crashes.length }}
            </v-btn>
            <v-expand-transition>
              <div v-show="showCrashes">
                <div
                  v-for="error in Object.entries(errors.crashes)"
                  :key="sessionName + Date.now() + error[0]"
                >
                  {{ error }}
                </div>
              </div>
            </v-expand-transition>
          </div>
        </div>
        <div v-else>
          No Errors ^^
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'ErrorsStats',

  props: ['errors', 'isExpanded', 'sessionName'],

  data() {
    return {
      showExceptions: false,
      showCrashes: false,
    }
  },

  filters: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  methods: {
    calculateTotalExceptions() {
      let exceptionsCount = 0
      Object.values(this.errors.exceptions).forEach(value => {
        exceptionsCount += Object.values(value).length
          ? Object.values(value)
              .map(exc => exc.quantity)
              .reduce((a, b) => a + b.quantity)
          : 0
      })
      return exceptionsCount
    },
  },
}
</script>

<style scoped>
.card__label {
  position: absolute;
  top: -36px;
  left: -8px;
}
</style>
