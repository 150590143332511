import { render, staticRenderFns } from "./InAppsStats.vue?vue&type=template&id=5fb7d718&scoped=true"
import script from "./InAppsStats.vue?vue&type=script&lang=js"
export * from "./InAppsStats.vue?vue&type=script&lang=js"
import style0 from "./InAppsStats.vue?vue&type=style&index=0&id=5fb7d718&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb7d718",
  null
  
)

export default component.exports