<template>
  <v-container>
    <v-card class="mx-auto" color="gray" width="80vw">
      <v-card-text>
        <div class="mb-3">
          And Finally Test The Playable
        </div>
        <v-expand-transition>
          <div v-show="!!playableID">
            <v-row class="text-center playable-content">
              <v-col cols="12" md="8">
                <div
                  class="d-flex justify-space-around flex-column"
                  :class="iframeDims"
                >
                  <iframe
                    :src="htmlUrl"
                    width="100%"
                    height="100%"
                    :sandbox="
                      blockRedirect
                        ? 'allow-scripts'
                        : 'allow-same-origin allow-scripts allow-popups allow-top-navigation'
                    "
                  ></iframe>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  :items="locales"
                  label="Language"
                  item-text="displayName"
                  item-value="code"
                  :value="locale"
                  @change="changeLocale"
                >
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          @click="reload"
                          :class="{ 'reloading-in-progress': isReloading }"
                        >
                          mdi-reload
                        </v-icon>
                      </template>
                      <span>Reload</span>
                    </v-tooltip>
                  </template>
                </v-autocomplete>

                <v-switch
                  v-model="viewRatio"
                  :label="viewRatio ? 'iPad' : 'iPhone'"
                ></v-switch>
                <v-switch
                  v-model="isLandscape"
                  :label="isLandscape ? 'Landscape' : 'Portrait'"
                ></v-switch>
                <v-switch
                  v-model="blockRedirect"
                  :label="
                    blockRedirect ? 'Redirect: blocked' : 'Redirect: allowed'
                  "
                ></v-switch>
                <!--              <v-switch-->
                <!--                v-model="isSilentModeOn"-->
                <!--                :label="isSilentModeOn ? 'Sound Off' : 'Sound On'"-->
                <!--              ></v-switch>-->
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DisplayPlayable',
  data: () => ({
    defaultExpansion: [],
    viewRatio: 1,
    isLandscape: 0,
    blockRedirect: 1,
    isReloading: 0,
    // isSilentModeOn: 1,
  }),
  watch: {
    rawHTML(newValue) {
      if (!this.defaultExpansion.length && !!newValue) {
        this.defaultExpansion.push(0)
      }
    },
    blockRedirect() {
      this.reload()
    },
  },
  computed: {
    ...mapState({
      rawHTML: state => state.playablePage.pageContent,
      locales: state => state.playablePage.locales,
      locale: state => state.playablePage.locale,
    }),
    ...mapGetters('playableLink', {
      url: 'url',
      isCorrect: 'isCorrectLink',
      playableID: 'playableID',
      htmlUrl: 'htmlUrl',
    }),
    iframeDims() {
      if (this.viewRatio && this.isLandscape) {
        return 'playable-ipad-landscape'
      }
      if (this.viewRatio && !this.landscape) {
        return 'playable-ipad-portrait'
      }
      if (!this.viewRatio && this.isLandscape) {
        return 'playable-iphone-landscape'
      } else {
        return 'playable-iphone-portrait'
      }
    },
  },
  methods: {
    changeLocale(locale) {
      this.$store.commit('playablePage/setLocale', locale)
      this.$store.commit('playableLink/setPlayableLocalizedUrl', { locale: locale })
      this.$store.commit('playablePage/setIsLocaleSet', true)
      this.$store.dispatch('playablePage/getPage', { url: this.htmlUrl })
    },
    reload() {
      this.isReloading = 1
      setTimeout(() => {
        this.isReloading = 0
      }, 1000)
      this.$store.commit('playablePage/setPageContent', { pageContent: '' })
      this.$store.dispatch('playablePage/getPage', { url: this.htmlUrl })
    },
  },
  mounted() {
    // console.log(navigator.language)
    if (this.locale === '') {
      this.$store.commit('playablePage/setLocale', navigator.language)
    }
  },
}
</script>

<style>
.hidden {
  display: none;
}

.playable-content {
  max-height: 750px;
}

iframe {
  display: block;
  position: relative;
}

.playable-iphone-landscape {
  height: 360px !important;
  max-width: 640px !important;
}

.playable-iphone-portrait {
  width: 360px !important;
  height: 640px !important;
}

.playable-ipad-landscape {
  height: 540px !important;
  max-width: 720px !important;
}

.playable-ipad-portrait {
  max-width: 540px !important;
  height: 720px !important;
}

.reloading-in-progress {
  -webkit-transform: rotate(360deg);
  -webkit-transition-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
</style>
