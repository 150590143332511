<template>
  <v-card class="pa-2 ma-2" tile v-if="inAppInfo">
    <v-card-title class="card__label">
      InApps Stats:
      <v-btn icon @click="isExpanded = !isExpanded">
        <v-icon>
          {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-show="!isExpanded" class="pa-2">
      <div>InApps: {{ inAppInfo.inApps.length || 0 }}</div>
      <div>Purchases: {{ inAppInfo.purchases.length || 0 }}</div>
    </v-card-text>
    <v-expand-transition>
      <div v-show="isExpanded">
        <v-list v-show="isExpanded" dense>
          <v-list-group
            :key="`${type}_gameStats`"
            v-for="type in Object.keys(inAppInfo)"
            :value="true"
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-title>
                {{ `${type}:` | capitalize }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="inapp in inAppInfo[type]"
              link
              :key="inapp.transaction_id + type"
            >
              <v-list-item-content>
                <v-list-item-title>{{ inapp.product_id }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ inapp.transaction_id }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                {{ inapp.price }} {{ inapp.currency }}
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'InAppsStats',

  props: ['inAppInfo', 'isExpanded', 'sessionName'],

  data() {
    return {
      statsTypes: ['level', 'scurrency', 'hcurrency', 'fps'],
    }
  },

  filters: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
}
</script>

<style scoped>
.card__label {
  position: absolute;
  top: -36px;
  left: -8px;
}
</style>
