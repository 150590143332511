<template>
  <LocalizationsComponent />
</template>

<script>
import LocalizationsComponent from '@/components/LocalizationsComponent'

export default {
  name: 'Localizations',
  components: {
    LocalizationsComponent,
  },
}
</script>
