<template>
  <v-container>
    <v-row v-if="gameScreen.imageUrl">
      <v-col md="6">
        <v-hover v-slot="{ hover }">
          <v-img
            @mousemove="moveImage"
            @mouseleave="resetImage"
            :contain="!hover"
            max-height="50vh"
            :src="editedScreen.imageUrl"
          ></v-img>
        </v-hover>
      </v-col>
      <v-col md="6">
        <v-combobox
          v-model="editedScreen.name"
          :items="gameScreens"
          item-text="name"
          label="Screen Name"
          :rules="[rules.required]"
        >
        </v-combobox>
        <v-textarea
          ref="screen_description"
          label="Description"
          v-model="editedScreen.description"
          counter
          clearable
          rows="2"
          auto-grow
        ></v-textarea>
        <GameMessagesOnScreen v-if="!editedScreen.file" />
        <v-checkbox
          v-if="!editedScreen.file"
          v-model="showGameMessagesForScreen"
          label="Show game messages for current screen"
        ></v-checkbox>
        <small>Revision: {{ editedScreen.revision }}</small>
        <v-row class="text-center">
          <v-col md="12">
            <v-btn
              class="ma-1"
              color="success"
              plain
              @click="saveScreen"
              :loading="isUpdating"
              :disabled="isUpdating"
            >
              Save
            </v-btn>
            <v-btn class="ma-1" color="warning" plain @click="discardChanges">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { trueClone } from '@/util'
import GameMessagesOnScreen from '@/components/plugins/GameMessagesOnScreen'

const emptyScreen = {
  imageUrl: '',
  name: '',
  description: '',
  revision: 0,
}

export default {
  name: 'GamesScreenView',
  components: { GameMessagesOnScreen },
  data: () => ({
    showGameMessagesForScreen: false,
    editedScreen: trueClone(emptyScreen),
    rules: {
      required: value => !!value || 'Select or input new screen name!',
    },
  }),
  watch: {
    showGameMessagesForScreen(newValue) {
      if (newValue) {
        this.$store.commit(
          'gameMessages/setScreenNameFilter',
          this.editedScreen.name
        )
      } else {
        this.$store.commit('gameMessages/setScreenNameFilter', '')
      }
    },
    'editedScreen.name'(newValue) {
      this.showGameMessagesForScreen = !this.showGameMessagesForScreen
      setTimeout(
        () =>
          (this.showGameMessagesForScreen = !this.showGameMessagesForScreen),
        100
      )
      console.log('editedScreen.name.newValue', newValue)
      if (newValue.constructor.name === 'Object') {
        let temp = trueClone(newValue)
        this.editedScreen.description = temp.description
        this.editedScreen.revision = temp.revision
        this.editedScreen.name = temp.name
        this.editedScreen.timestamp = temp.timestamp
      } else {
        if (!this.editedScreen.timestamp) {
          this.editedScreen.revision = 0
        }
      }
    },
    gameScreen(newValue) {
      this.editedScreen = trueClone({ ...emptyScreen, ...newValue })
    },
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      gameMessages: state => state.gameMessages.gameMessages,
      gameScreens: state => state.gameScreens.screens,
      gameScreen: state => state.gameScreens.screen,
      isUpdating: state => state.gameScreens.isUpdating,
    }),
    filteredKeys() {
      return this.keys.filter(key => key !== 'Name')
    },
  },
  methods: {
    saveScreen() {
      this.$refs.screen_description.focus()
      setTimeout(() => {
        if (this.editedScreen.name) {
          this.$store.commit('gameScreens/setGameScreen', this.editedScreen)
          this.$store.dispatch('gameScreens/upsertGameScreen')
          setTimeout(
            () => this.$emit('process-screen', this.editedScreen),
            5000
          )
        } else {
          this.$store.commit(
            'pushTextErrorNotification',
            'Select or input new screen name!',
            {
              root: true,
            }
          )
        }
      }, 200)
    },
    discardChanges() {
      this.editedScreen = trueClone({ ...emptyScreen, ...this.gameScreen })
    },
    moveImage(e) {
      const el = e.srcElement.parentElement.getElementsByClassName(
        'v-image__image'
      )[0]
      if (el.clientWidth / el.clientHeight < 1.2) {
        // el.style.backgroundPositionX = 50 - e.offsetX * 1.7 + 'px'
        el.style.backgroundPositionY = 50 - e.offsetY * 1.7 + 'px'
      }
    },
    resetImage(e) {
      const el = e.srcElement.parentElement.getElementsByClassName(
        'v-image__image'
      )[0]
      // el.style.backgroundPositionX = '0px'
      el.style.backgroundPositionY = '0px'
    },
  },
  mounted() {},
}
</script>

<style scoped></style>
