<template>
  <v-container class="playable-link">
    <v-card class="mx-auto" color="gray" width="80vw">
      <v-card-text>
        <div>
          First Put Your Playable Link Here
        </div>
        <v-row class="text-center" align="center" justify="space-around">
          <v-col class="mb-5" cols="12">
            <v-text-field
              ref="playableLink"
              v-model="tempUrl"
              :rules="playableLinkRules"
              label="Playable link"
              :loading="isPlayableLoading"
              required
              @keydown="setUrl"
              @click:append="setUrl"
              append-icon="mdi-send"
              hint="https://playables.saygames.io/playable/BAdVhzKYoIgaK5ME"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div class="mb-3">
          Then edit some Localization settings
        </div>
        <v-expand-transition>
          <div v-show="localizationPayload">
            <v-row class="text-center" align="center" justify="space-around">
              <v-col cols="12" md="8">
                <v-textarea
                  name="localizationPayload"
                  label="Localization JSON"
                  :value="JSON.stringify(localizationPayload)"
                  auto-grow
                  disabled
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  text
                  :loading="downloadLoading"
                  :disabled="downloadLoading || !localizationPayload"
                  color="success"
                  class="ma-2 white--text"
                  @click="downloadCSV"
                >
                  Download
                  <v-icon right dark>
                    mdi-cloud-download
                  </v-icon>
                </v-btn>
                <a class="hidden" ref="downloadLink"></a>
              </v-col>
              <v-col cols="12" md="8">
                <div v-if="!file">
                  <div
                    :class="['dropZone', dragging ? 'dropZone-over' : '']"
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                  >
                    <div class="dropZone-info" @drag="onChange">
                      <span class="fa fa-cloud-upload dropZone-title"></span>
                      <span class="dropZone-title">
                        Drop file or click to upload
                      </span>
                    </div>
                    <input type="file" @change="onChange" />
                  </div>
                </div>
                <div v-else class="dropZone-uploaded">
                  <div class="dropZone-uploaded-info">
                    <span class="dropZone-title">Uploaded</span>
                    <button
                      type="button"
                      class="btn btn-primary removeFile"
                      @click="removeFile"
                    >
                      Remove File
                    </button>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  text
                  :loading="uploadLoading"
                  :disabled="uploadLoading || !file"
                  color="warning"
                  class="ma-2 white--text"
                  @click="uploadCSV"
                >
                  Save
                  <v-icon right dark>
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { csvToJSON, jsonToCSV } from '@/util'
import api from '@/api/api'

export default {
  name: 'ProcessPlayable',
  data: () => ({
    file: undefined,
    dragging: false,
    loader: null,
    downloadLoading: false,
    uploadLoading: false,
    defaultExpansion: [0],
    tempUrl: '',
    isPlayableLoading: false,
    linkHasErrors: true,
    playableLinkRules: [
      v => !!v || 'Link is required',
      v =>
        !!/.*playables\.saygames\.io\/(playable|client\/playables)(?:(\/show))?\/([a-zA-Z0-9]*)$/.test(v) ||
        'Playable link should looks like: https://playables.saygames.io/playable/{id}',
    ],
  }),
  watch: {
    pageContent(newValue) {
      if (newValue) {
        this.isPlayableLoading = false
      }
    },
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 1000)

      this.loader = null
    },
  },
  computed: {
    ...mapState({
      url: state => state.playableLink.url,
      localizationPayload: state => state.playablePage.localizationPayload,
    }),
    ...mapGetters('playableLink', {
      url: 'url',
      isCorrect: 'isCorrectLink',
      playableID: 'playableID',
      htmlUrl: 'htmlUrl',
    }),
    ...mapGetters('playablePage', {
      pageContent: 'pageContent',
      spVarsType: 'spVarsType',
    }),
  },
  methods: {
    selectFile(file) {
      this.currentFile = file
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        this.dragging = false
        return
      }

      this.createFile(files[0])
    },
    createFile(file) {
      // if (
      //   !file.type.match('text.*') &&
      //   !file.type.match('application/vnd.ms-excel')
      // ) {
      //   this.$store.commit(
      //     'pushTextErrorNotification',
      //     'Incorrect File format',
      //     { root: true }
      //   )
      //   this.dragging = false
      //   return
      // }

      if (file.size > 15000000) {
        this.$store.commit(
          'pushTextErrorNotification',
          'File size limit: 15MB.',
          { root: true }
        )
        this.dragging = false
        return
      }

      this.file = file
      this.dragging = false
    },
    removeFile() {
      this.file = ''
    },
    setUrl(e) {
      if (e.type === 'click' || (e.type === 'keydown' && e.code === 'Enter')) {
        this.isPlayableLoading = true
        this.$store.dispatch('playableLink/parseLink', { link: this.tempUrl })
        if (!this.$refs.playableLink.errorBucket.length) {
          this.defaultExpansion.pop()
          this.defaultExpansion.push(1)
        }
        this.$store.dispatch('playablePage/getPage', { url: this.htmlUrl })
      }
    },
    downloadCSV() {
      this.loader = 'downloadLoading'
      jsonToCSV(this.localizationPayload)
        .then(csvString => {
          this.$refs.downloadLink.href = `data:application/csv;charset=utf-8,${encodeURI(
            csvString
          )}`
          this.$refs.downloadLink.download = `${this.playableID}.csv`
          this.$refs.downloadLink.click()
        })
        .catch(error => {
          this.$store.commit('pushErrorNotification', error, { root: true })
        })
    },
    uploadCSV() {
      let fileContent
      this.loader = 'uploadLoading'
      const reader = new FileReader()
      reader.addEventListener('load', event => {
        fileContent = event.target.result
        csvToJSON(fileContent).then(json => {
          api
            .updateLocalization({
              playableID: this.playableID,
              localizationPayload: json,
              spVarsType: this.spVarsType,
            })
            .then(response => {
              console.log(response)
              setTimeout(() => {
                this.reload()
              }, 3000)
              this.file = undefined
              this.defaultExpansion.pop()
            })
            .catch(error => {
              this.$store.commit('pushErrorNotification', error, {
                root: true,
              })
            })
        })
          .catch(error => {
            this.$store.commit('pushErrorNotification', error, {
              root: true,
            })
          })
      })
      reader.readAsText(this.file, 'utf-8')
    },
    reload() {
      this.$store.commit('playablePage/setPageContent', {
        pageContent: '',
      })
      this.$store.dispatch('playablePage/getPage', {
        url: this.htmlUrl,
      })
    },
  },
}
</script>

<style scoped>
.playable-link {
  margin-top: 40px;
}

.overlay {
  position: inherit !important;
}

.hidden {
  display: none;
}

.dropZone {
  width: 100%;
  height: 36px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-uploaded {
  width: 100%;
  height: 36px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #a8a8a8;
  position: relative;
  top: 50%;
  width: 100%;
  transform: translate(30%, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
