<template>
  <v-container>
    <v-card class="mx-auto" color="gray" width="80vw">
      <v-card-text>
        <div>Select Project</div>
        <v-row class="text-center">
          <v-col cols="12" md="5">
            <v-autocomplete
              :items="projects"
              label="Select Project"
              :filter="filterProjects"
              v-model="project"
              return-object
              item-text="Name"
              item-value="ID"
              clearable
              @change="loadProjectById"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.Name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.Company"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <span v-text="item.Name"></span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="5">
            <div v-if="!file" class="dropZone-container">
              <div
                :class="['dropZone', dragging ? 'dropZone-over' : '']"
                @dragenter="dragging = true"
                @dragleave="dragging = false"
              >
                <div class="dropZone-info" @drag="onChange">
                  <span class="fa fa-cloud-upload dropZone-title"></span>
                  <span class="dropZone-title">
                    Drop here csv with locale
                  </span>
                </div>
                <input
                  type="file"
                  @change="onChange"
                  :disabled="project && !project.ID"
                />
              </div>
            </div>
            <div v-else class="dropZone-uploaded">
              <div class="dropZone-uploaded-info">
                <span class="dropZone-title">Uploaded</span>
                <button
                  type="button"
                  class="btn btn-primary removeFile"
                  @click="removeFile"
                >
                  Remove File
                </button>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              icon
              :disabled="
                downloadLoading || (gameMessages && !gameMessages.length)
              "
              color="success"
              class="mt-4 ml-2 mr-2 mb-2 white--text"
              @click="toggleImages = !toggleImages"
            >
              <v-icon right dark>
                mdi-image-multiple-outline
              </v-icon>
            </v-btn>
            <v-btn
              icon
              :loading="downloadLoading"
              :disabled="
                downloadLoading || (gameMessages && !gameMessages.length)
              "
              color="success"
              class="mt-4 ml-2 mr-2 mb-2 white--text"
              @click="toggleGameMessages = !toggleGameMessages"
            >
              <v-icon right dark>
                mdi-grid
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ImagesView :toggle-images="toggleImages" />
    <GameMessages :toggle-game-messages="toggleGameMessages" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { csvToJSONLockit, jsonToCSVLockit } from '@/util'
import ImagesView from '@/components/ImagesView'
import GameMessages from '@/components/GameMessages'

export default {
  name: 'LocalizationsComponent',
  components: {
    GameMessages,
    ImagesView,
  },
  data: () => ({
    toggleImages: true,
    toggleGameMessages: true,
    project: {},
    file: undefined,
    dragging: false,
    loader: null,
    downloadLoading: false,
    uploadLoading: false,
    defaultExpansion: [0],
  }),
  watch: {},
  computed: {
    ...mapState({
      projects: state => state.projects.projects,
      gameMessages: state => state.gameMessages.gameMessages,
    }),
  },
  methods: {
    filterProjects(item, queryText) {
      const name = item.Name.toLowerCase()
      const company = item.Company.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 || company.indexOf(searchText) > -1
    },
    loadProjectById() {
      if (this.project) {
        this.$store.dispatch('projects/getProjectById', {
          projectId: this.project.ID,
        })
      } else {
        this.$store.dispatch('projects/getProjectById', {
          projectId: undefined,
        })
      }
      this.$store.dispatch('gameMessages/getGameMessagesForCurrentProject')
    },
    selectFile(file) {
      this.currentFile = file
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        this.dragging = false
        return
      }

      this.createFile(files[0])
    },
    createFile(file) {
      if (file.size > 5000000) {
        this.$store.commit(
          'pushTextErrorNotification',
          'File size limit: 5MB.',
          { root: true }
        )
        this.dragging = false
        return
      }

      this.file = file
      this.uploadCSV()
      this.dragging = false
    },
    removeFile() {
      this.file = ''
    },
    downloadCSV() {
      this.loader = 'downloadLoading'
      let gameMessagesJson = Object.assign({}, this.gameMessages)
      for (let message in gameMessagesJson) {
        delete message.state
        delete message.screen
        delete message.box
        delete message.limit
      }
      jsonToCSVLockit(gameMessagesJson)
        .then(csvString => {
          this.$refs.downloadLink.href = `data:application/csv;charset=utf-8,${encodeURI(
            csvString
          )}`
          this.$refs.downloadLink.download = `${this.project.ProjectName}.csv`
          this.$refs.downloadLink.click()
        })
        .catch(error => {
          this.$store.commit('pushErrorNotification', error, { root: true })
        })
    },
    uploadCSV() {
      let fileContent
      this.loader = 'uploadLoading'
      const reader = new FileReader()
      reader.addEventListener('load', event => {
        fileContent = event.target.result
        csvToJSONLockit(fileContent).then(json => {
          this.$store.dispatch('gameMessages/processMessages', {
            messages: json,
          })
          this.file = undefined
        })
      })
      reader.readAsText(this.file, 'utf-8')
    },
  },
  mounted() {
    this.$store.dispatch('projects/getProjects')
  },
}
</script>

<style>
.hidden {
  display: none;
}

.dropZone-container {
  margin-top: 15px;
}

.dropZone {
  width: 100%;
  height: 36px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-uploaded {
  width: 100%;
  height: 36px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #a8a8a8;
  position: relative;
  top: 50%;
  width: 100%;
  transform: translate(30%, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}

.header-select {
  width: 0;
}

.game-messages td {
  min-width: 200px;
}
</style>
