<template>
  <v-container>
    <v-card className="mx-auto" color="gray" width="80vw">
      <v-card-text>
        <div>Debug View</div>
        <v-row class="text-center ml-1">
          <v-col cols="12" md="4">
            <v-autocomplete
              :items="projects"
              label="Select Project"
              :filter="filterProjects"
              v-model="project"
              return-object
              item-text="Name"
              item-value="ID"
              clearable
              @change="loadProjectById"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.Name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.Company"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <span v-text="item.Name"></span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-autocomplete
                  label="Device"
                  :items="devices"
                  item-text="idfa"
                  return-object
                  v-model="selectedDevice"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            :key="`icon-${editMode}`"
                            :color="
                              selectedDevice && selectedDevice.idfa
                                ? 'success'
                                : 'info'
                            "
                            @click="editMode = !editMode"
                            v-text="
                              selectedDevice && selectedDevice.idfa
                                ? 'mdi-cloud-upload-outline'
                                : 'mdi-plus'
                            "
                            v-bind="attrs"
                            v-on="on"
                          ></v-icon>
                        </template>
                        <span>{{
                          selectedDevice && selectedDevice.idfa
                            ? 'Update device data'
                            : 'Add Device'
                        }}</span>
                      </v-tooltip>
                    </v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>
              </template>
              <span v-if="selectedDevice">
                IDFA: {{ selectedDevice.idfa }} Device ID:
                {{ selectedDevice.device_id }} Device OS:
                {{ selectedDevice.device_os }} IP: {{ selectedDevice.ip }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              v-model="showMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Session Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="showMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="1">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mt-5"
                  dark
                  color="primary"
                  :disabled="!selectedDevice || !selectedDevice.idfa"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-update
                </v-icon>
              </template>
              <span>Enable Autoupdate (5sec)</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row class="text-center ml-1" v-show="sessions.length">
            <v-col cols="12" md="4">
              <v-select
                v-model="currentSession"
                :items="sessions"
                item-text="name"
                return-object
                label="Session"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <!--            <v-col md="4">-->
            <!--              <v-checkbox-->
            <!--                v-model="maximize"-->
            <!--                label="Display full text"-->
            <!--              ></v-checkbox>-->
            <!--            </v-col>-->
          </v-row>
        </v-expand-transition>

        <v-expand-transition>
<!--                    <v-row v-show="currentSession" no-gutters style="flex-wrap: nowrap;">-->
          <v-row v-show="currentSession">
            <v-col cols="4" class="flex-grow-0 flex-shrink-0">
              <Status
                :sessionName="currentSession.name"
                :status="currentSession.status"
                :isExpanded="expandedPanels.status"
              />
            </v-col>
            <v-col cols="4" class="flex-grow-0 flex-shrink-0">
              <AdsStats
                :sessionName="currentSession.name"
                :ads_stats="currentSession.ads_stats"
                :is-expanded="expandedPanels.adsStats"
              />
            </v-col>
            <v-col cols="4" class="flex-grow-0 flex-shrink-0">
              <GameStats
                :sessionName="currentSession.name"
                :gameStats="currentSession.gameStats"
                :isExpanded="expandedPanels.gameStats"
              />
            </v-col>
            <v-col cols="7" class="flex-grow-0 flex-shrink-0">
              <ErrorsStats
                :sessionName="currentSession.name"
                :errors="currentSession.errors"
                :isExpanded="expandedPanels.errors"
              />
            </v-col>
            <v-col cols="5" class="flex-grow-0 flex-shrink-0">
              <InAppsStats
                :sessionName="currentSession.name"
                :inAppInfo="currentSession.inAppInfo"
                :isExpanded="expandedPanels.inApps"
              />
            </v-col>
            <v-col
              cols="12"
              style="min-width: 100px; max-width: 100%;"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-card elevation="0" tile>
                <v-card-text ref="logs">
                  <template v-for="event in logs">
                    <div
                      class="mb-3"
                      :key="
                        `event_${event.server_time}_${event.sequence}_${event.event}`
                      "
                    >
                      <v-badge
                        :key="
                          `event_${event.server_time}_${event.sequence}_${event.event}`
                        "
                        offset-x="12"
                        offset-y="12"
                        bordered
                        color="info lighten-2"
                        :content="event.sequence"
                      >
                        <v-chip @click="maximize = !maximize">
                          {{ event.event }}
                        </v-chip>
                      </v-badge>

                      {{ event.param1 }}
                      <v-expand-transition
                        :key="
                          `event_${event.server_time}_${event.sequence}_${event.event}_content`
                        "
                      >
                        <v-row
                          :key="
                            `event_${event.server_time}_${event.sequence}_${event.event}_content`
                          "
                          v-show="maximize"
                          class="pa-0 ma-0"
                          no-gutters
                          style="flex-wrap: nowrap;"
                        >
                          <v-col
                            style="min-width: 12vw; max-width: 12vw;"
                            class="flex-grow-1 flex-shrink-1"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  class="black--text text-sm-caption"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ event.server_time }}
                                </span>
                              </template>
                              <span>Server time</span>
                            </v-tooltip>
                            <v-spacer />
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  class="text-sm-caption"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ event.client_time }}
                                </span>
                              </template>
                              <span>Client time</span>
                            </v-tooltip>
                            <v-spacer />
                            <span class="text-sm-caption black--text">
                              Version: {{ event.version }}
                            </span>
                          </v-col>
                          <v-col class="flex-grow-1 flex-shrink-0">
                            <span
                              class="black--text text-sm-caption"
                              v-if="event.extra"
                            >
                              Extra: {{ event.extra }}
                            </span>
                            <v-spacer />
                            <span
                              class="blue--text text-sm-caption"
                              v-if="event.extra2"
                            >
                              Extra2: {{ event.extra2 }}
                            </span>
                            <v-spacer />
                            <span class="text-sm-caption" v-if="event.param1">
                              Param1: {{ event.param1 }}
                            </span>
                            <v-spacer />
                            <span class="text-sm-caption" v-if="event.param2">
                              Param2: {{ event.param2 }}
                            </span>
                            <v-spacer />
                          </v-col>
                        </v-row>
                      </v-expand-transition>
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-card-text>
      <v-overlay :value="editMode">
        <v-card class="mx-auto" width="40vw">
          <v-card-text>
            <div>Add device:</div>
            <v-text-field
              ref="deviceIDFA"
              v-model="editedDevice.idfa"
              :rules="idfaRules"
              label="IDFA"
              required
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="success" @click="() => {}">
              Add
            </v-btn>
            <v-btn text color="secondary" @click="editMode = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import { getSessions } from '@/plugins/launcherApi'
import Status from '../components/layout/debugView/Status'
import AdsStats from '../components/layout/debugView/AdsStats'
import GameStats from '../components/layout/debugView/GameStats'
import ErrorsStats from '../components/layout/debugView/Errors'
import InAppsStats from '../components/layout/debugView/InAppsStats'
import { mapState } from 'vuex'

export default {
  name: 'DebugViewContainer',
  components: {
    ErrorsStats,
    AdsStats,
    GameStats,
    InAppsStats,
    Status,
  },
  data: () => ({
    currentSession: {},
    offset: 0,
    page: 0,
    logsPerPage: 10,
    logs: [],
    expandedPanels: {
      status: false,
      adsStats: false,
      gameStats: false,
      errors: true,
      inApps: false,
    },
    maximize: true,
    search: '',
    showMenu: false,
    showMonetizations: false,
    date: new Date().toISOString().substr(0, 10),
    game: {},
    editMode: false,
    selectedDevice: null,
    editedDevice: { idfa: '' },
    devices: [
      {
        idfa: '89B84DCF-EBBC-4D82-93D8-C3F576DACBFC',
        device_id: '4181398D-B9AB-4EEE-B7B2-23E99304A3E1',
        ip: '194.183.166.88',
        user: 'o4cbgTx73yd9WkGuu2vJdclYpsl1',
        device_os: 'iOS 14.7',
        country: 'UA',
        device_name: 'iPhone11,8',
      },
    ],
    idfaRules: [
      v => !!v || 'IDFA is required',
      v =>
        !!/[A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12}$/.test(
          v
        ) || 'IDFA Should looks like: 00000000-0000-0000-0000-000000000000',
    ],
    sessions: getSessions(),
  }),
  watch: {},
  computed: {
    ...mapState({
      projects: state => state.projects.projects,
      gameMessages: state => state.gameMessages.gameMessages,
    }),
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  methods: {
    filterProjects(item, queryText) {
      const name = item.Name.toLowerCase()
      const company = item.Company.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 || company.indexOf(searchText) > -1
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    summaryForEvent(event, prevEvent) {
      event.summary = {}
      if (event.extra && event.extra.length < 50) {
        event.summary.description = event.extra
      }
      if (event.extra2 && event.extra2.length < 50) {
        event.summary.description2 = event.extra2
      }
      if (
        (event.event.find('_loaded') || event.event.find('_imp')) &&
        (!event.event.find('saykit') ||
          !event.event.find('max') ||
          !event.event.find('_config'))
      ) {
        event.summary.description = JSON.parse(event.extra)['network_name']
      } else if (
        (event.event.find('_loaded') || event.event.find('_imp')) &&
        event.event.find('max')
      ) {
        event.summary.description = JSON.parse(event.extra)['network']
      }
      if (event.event === 'app_start') {
        event.summary.description = `Saykit ${event.param1}`
      }
      if (event.event === 'saykit_loaded') {
        event.summary.description = `Sec ${event.param1}, Attempt: ${event.param2}`
      }
      if (event.event === 'fps') {
        event.summary.description = event.param1
      }
      if (event.level !== prevEvent.level) {
        event.summary.levelChanged = {
          from: event.level,
          to: prevEvent.level,
        }
      }
      if (event.scurrency !== prevEvent.scurrency) {
        event.summary.scurrencyChanged = {
          from: event.scurrency,
          to: prevEvent.scurrency,
        }
      }
      if (event.hcurrency !== prevEvent.hcurrency) {
        event.summary.hcurrencyChanged = {
          from: event.hcurrency,
          to: prevEvent.hcurrency,
        }
      }
    },

    onScroll() {
      let logsHeading = this.$refs['logs']
      if (logsHeading) {
        const marginTopUsers = logsHeading.getBoundingClientRect().top
        const innerHeight = window.innerHeight

        if (marginTopUsers - innerHeight < -50) {
          this.page += 1
          this.logs = this.currentSession.events
            ? this.currentSession.events.slice(0, this.page * this.logsPerPage)
            : []
        }
      }
    },
  },

  mounted() {
    this.$nextTick(function() {
      window.addEventListener('scroll', this.onScroll)
      this.onScroll() // needed for initial loading on page
    })
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
