import { gameScreensCollection, storage } from '@/plugins/firebase'
import { trueClone } from '@/util'

const state = () => ({
  screens: [],
  screen: {},
  search: '',
  isUpdating: false,
  isCollectionUpdating: false,
})

// getters
const getters = {
  screens: state => {
    return state.screens
  },
  screen: state => {
    return state.screen
  },
}

// actions
const actions = {
  getGameScreens({ commit, rootState }, { projectID }) {
    commit('setCollectionLoading')
    // console.log(`getGameScreens.filter`, filter)
    let filterProjectID = projectID || rootState.projects.project.ProjectID
    console.log('filterProjectID', filterProjectID)
    if (projectID) {
      gameScreensCollection
        .where('projectID', '==', filterProjectID)
        .get()
        .then(snap => {
          const array = []
          snap.forEach(doc => {
            array.push(doc.data())
          })
          commit('setGameScreens', array)
          commit('setCollectionLoaded')
        })
    } else {
      commit('setGameScreens', [])
    }
  },

  upsertGameScreen({ state, commit, rootState }) {
    commit('setLoading')
    const file = state.screen.file
    const projectID = rootState.projects.project.ProjectID
    const docName = state.screen.name.toLowerCase().replaceAll(' ', '_')
    if (file) {
      const storageRef = storage.ref()
      const imageRef = storageRef.child(`images/${docName}_${projectID}`)
      imageRef.put(file).then(snapshot => {
        snapshot.ref.getDownloadURL().then(downloadURL => {
          gameScreensCollection
            .doc(`${docName}_${projectID}`)
            .set({
              name: state.screen.name,
              description: state.screen.description,
              projectID: projectID,
              revision: state.screen.revision + 1,
              imageUrl: downloadURL,
              timestamp: Date.now(),
            })
            .then(() => {
              commit(
                'pushNotification',
                {
                  header: 'Success',
                  message: `Screen: ${state.screen.name} saved`,
                  color: 'success',
                },
                {
                  root: true,
                }
              )
              commit('setLoaded')
            })
            .catch(error => {
              commit('pushErrorNotification', error, { root: true })
              commit('setLoaded')
            })
        })
      })
    } else {
      gameScreensCollection
        .doc(docName)
        .update({
          name: state.screen.name,
          description: state.screen.description,
          projectID: state.screen.projectID,
          revision: state.screen.revision + 1,
          imageUrl: state.screen.imageUrl,
          timestamp: Date.now(),
        })
        .then(() => {
          commit('setGameScreen', {
            ...state.screen,
            revision: state.screen.revision + 1,
          })
          commit(
            'pushNotification',
            {
              header: 'Success',
              message: `Screen: ${state.screen.name} saved`,
              color: 'success',
            },
            {
              root: true,
            }
          )
          commit('setLoaded')
        })
        .catch(error => {
          commit('pushErrorNotification', error, { root: true })
          commit('setLoaded')
        })
    }
  },
}

// mutations
const mutations = {
  setGameScreen(state, screen) {
    state.screen = trueClone(screen)
  },

  setGameScreens(state, screens) {
    state.screens = screens
  },

  setLoading(state) {
    state.isUpdating = true
  },

  setLoaded(state) {
    state.isUpdating = false
  },

  setCollectionLoading(state) {
    state.isCollectionUpdating = true
  },

  setCollectionLoaded(state) {
    state.isCollectionUpdating = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
