<template>
  <v-autocomplete
    :items="gameMessages"
    label="Add keys to screen"
    :filter="filterGameMessages"
    v-model="messagesOnScreen"
    return-object
    clearable
    multiple
    item-text="code"
    item-value="code"
    @change="updateGameMessages"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title
          class="clip-text"
          v-text="data.item.text_en"
        ></v-list-item-title>
        <v-list-item-subtitle v-text="data.item.code"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attr"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <span class="clip-text" v-text="data.item.text_en"></span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
import { trueClone } from '@/util'

export default {
  name: 'GameMessagesOnScreen',

  data() {
    return {
      messagesOnScreen: [],
      initialized: false,
    }
  },

  watch: {
    screen() {
      this.initialized = false
      this.messagesOnScreen = trueClone(
        this.gameMessages.filter(
          message =>
            message.screens && message.screens.includes(this.screen.name)
        )
      )
    },
    messagesOnScreen(newValue, oldValue) {
      if (this.initialized) {
        let newItems = newValue.map(item => item.code)
        const oldItems = oldValue.map(item => item.code)
        const itemsToAdd = newValue.filter(
          item => !oldItems.includes(item.code)
        )
        const itemsToRemove = oldValue.filter(
          item => !newItems.includes(item.code)
        )
        itemsToAdd.forEach(item =>
          this.$store.dispatch('gameMessages/addGameMessageToScreen', {
            gameMessage: item,
            screenName: this.screen.name,
          })
        )
        itemsToRemove.forEach(item =>
          this.$store.dispatch('gameMessages/removeGameMessageFromScreen', {
            gameMessage: item,
            screenName: this.screen.name,
          })
        )
      }
      this.initialized = true
    },
  },

  computed: {
    ...mapState({
      gameMessages: state => state.gameMessages.gameMessages,
      screen: state => state.gameScreens.screen,
    }),
  },

  methods: {
    filterGameMessages(item, queryText) {
      const code = item.code.toLowerCase()
      const text_en = item.text_en.toLowerCase()
      const searchText = queryText.toLowerCase()

      return code.indexOf(searchText) > -1 || text_en.indexOf(searchText) > -1
    },

    updateGameMessages() {
      console.log('updateGameMessages')
    },

    remove(item) {
      this.messagesOnScreen = this.messagesOnScreen.filter(message => {
        return message.code !== item.code
      })
    },
  },

  mounted() {
    this.messagesOnScreen = trueClone(
      this.gameMessages.filter(
        message => message.screens && message.screens.includes(this.screen.name)
      )
    )
  },
}
</script>

<style scoped>
.clip-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
