<template>
  <div class="home">
    <ProcessPlayable />
    <DisplayPlayable />
  </div>
</template>

<script>
import ProcessPlayable from '@/components/ProcessPlayable.vue'
import DisplayPlayable from '@/components/DisplayPlayable'

export default {
  name: 'Playables',
  components: {
    ProcessPlayable,
    DisplayPlayable,
  },
}
</script>
