import axios from 'axios'
import config from '@/config/config'

const adjustConfig = {
  baseURL: 'https://api.adjust.com',
  headers: {
    Authorization: `Bearer ${config.apiToken}`,
  },
}

export default {
  getApps() {
    return new Promise((resolve, reject) => {
      axios
        .get('/dashboard/api/apps', adjustConfig)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getDeviceData({ app_token, idfa }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/device_service/api/v1/inspect_device?advertising_id=${idfa}&app_token=${app_token}&format=json`,
          adjustConfig
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  forgetDevice({ app_token, adid }) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/device_service/api/v1/forget_device?adid=${adid}&app_token=${app_token}&format=json`,
          {},
          adjustConfig
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
