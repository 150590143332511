<template>
  <v-slide-x-transition>
    <div v-show="userProfile.email">
      <v-app-bar app color="primary">
        <a class="d-flex align-center" href="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-0"
            contain
            src="@/assets/gsdlogo.svg"
            transition="scale-transition"
            width="72"
          />
          <h1 class="text-h6 white--text">
            Service Tools
          </h1>
        </a>

        <v-spacer></v-spacer>
        <!--      <router-link to="/playables" v-slot="{ href, route, navigate, isActive }">-->
        <!--        <a>-->
        <!--          <v-btn-->
        <!--            :active="isActive"-->
        <!--            :href="href"-->
        <!--            @click="navigate"-->
        <!--            class="ma-1"-->
        <!--            color="white"-->
        <!--            plain-->
        <!--          >-->
        <!--            {{ route.name }}-->
        <!--          </v-btn>-->
        <!--        </a>-->
        <!--      </router-link>-->
        <a v-if="userProfile.email">
          <v-btn @click="logout" class="ma-1" color="white" plain>
            logout
          </v-btn>
        </a>
        <a v-else>
          <router-link to="/login" v-slot="{ href, route, navigate, isActive }">
            <a>
              <v-btn
                :active="isActive"
                :href="href"
                @click="navigate"
                class="ma-1"
                color="white"
                plain
              >
                {{ route.name }}
              </v-btn>
            </a>
          </router-link>
        </a>
      </v-app-bar>
      <v-navigation-drawer
        v-if="userProfile.email"
        absolute
        class="pt-15"
        color="secondary lighten-2"
        permanent
        expand-on-hover
        ref="drawer"
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar color="primary" size="42">
              <span class="white--text text-h6">
                {{ initials.fnln }}
              </span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6 white--text">
                {{ initials.firstName | capitalize }}
                {{ initials.lastName | capitalize }}
              </v-list-item-title>
              <v-list-item-subtitle class="white--text">
                {{ userProfile.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav dense>
          <template v-for="routeObject in $router.getRoutes()">
            <router-link
              :key="routeObject.name"
              v-if="!routeObject.meta.hidden"
              :to="routeObject.path"
              v-slot="{ href, route, navigate, isActive }"
            >
              <v-list-item
                v-if="!routeObject.meta.hidden"
                link
                :active="isActive"
                :href="href"
                @click="navigate"
              >
                <v-list-item-icon>
                  <v-icon class="white--text">{{ route.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="white--text">
                  {{ route.name }}
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </template>
        </v-list>
      </v-navigation-drawer>
    </div>
  </v-slide-x-transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Menu',

  // components: {
  // },

  computed: {
    ...mapState({
      userProfile: state => state.auth.userProfile,
    }),

    initials() {
      const emailName = this.userProfile.email.split('@')[0]
      let [firstName, lastName] = emailName.split('.')
      return {
        firstName,
        lastName,
        fnln: `${firstName[0]}${lastName ? lastName[0] : ''}`.toUpperCase(),
      }
    },
  },

  filters: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
}
</script>

<style scoped></style>
