<template>
  <v-card v-if="toggleGameMessages" class="mt-1">
    <v-card-title>
      Game Messages
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-select
            class="header-select mt-8 langs"
            v-model="editedLanguages"
            :items="allLanguages"
            multiple
            item-text="text"
            item-value="value"
            return-object
            dense
            rounded
          >
            <template v-slot:selection>
              <div></div>
            </template>
            <template v-slot:append>
              <v-icon v-bind="attrs" v-on="on">
                mdi-translate
              </v-icon>
            </template>
          </v-select>
        </template>
        <span>Tap here to select languages.</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-select
            class="header-select mt-8 langs"
            v-model="filter"
            :items="states"
            multiple
            dense
            rounded
          >
            <template v-slot:selection>
              <div></div>
            </template>
            <template v-slot:append>
              <v-icon v-bind="attrs" v-on="on">
                mdi-filter-variant
              </v-icon>
            </template>
          </v-select>
        </template>
        <span>Tap here to filter messages by message state.</span>
      </v-tooltip>
    </v-card-title>
    <v-data-table
      class="game-messages-table"
      dense
      :headers="tableHeaders"
      :items="gameMessages"
      :search="search"
      :key="keyChanger"
      :loading="isUpdating"
      loading-text="Getting new strings from server"
    >
      <template v-slot:item.state="props">
        <v-edit-dialog
          @save="saveScreens"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          <v-chip :color="getColor(props.item)" x-small>
            {{ props.item.state.toUpperCase() }}
          </v-chip>
          <template v-slot:input>
            <v-select
              :items="states"
              label="Set game message state"
              v-model="editedElement.state"
              return-object
              autofocus
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-text="data.item"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.code="props">
        {{ props.item.code }}
      </template>
      <template v-slot:item.text_en="props">
        <v-edit-dialog
          @save="save(editedElement.text_en)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_en }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_en"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_ru="props">
        <v-edit-dialog
          @save="save(editedElement.text_ru)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_ru }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_ru"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_de="props">
        <v-edit-dialog
          @save="save(editedElement.text_de)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_de }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_de"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_fr="props">
        <v-edit-dialog
          @save="save(editedElement.text_fr)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_fr }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_fr"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_es="props">
        <v-edit-dialog
          @save="save(editedElement.text_es)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_es }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_es"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_it="props">
        <v-edit-dialog
          @save="save(editedElement.text_it)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_it }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_it"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_pt="props">
        <v-edit-dialog
          @save="save(editedElement.text_pt)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_pt }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_pt"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_ja="props">
        <v-edit-dialog
          @save="save(editedElement.text_ja)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_ja }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_ja"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_zh="props">
        <v-edit-dialog
          @save="save(editedElement.text_zh)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_zh }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_zh"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.text_ko="props">
        <v-edit-dialog
          @save="save(editedElement.text_ko)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          {{ props.item.text_ko }}
          <template v-slot:input>
            <v-textarea
              label="Write new text"
              v-model="editedElement.text_ko"
              :rules="[rules.required, rules.minLength]"
              counter
              class="game-messages-edit-textarea"
              rows="4"
              clearable
              auto-grow
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.limit="props">
        <v-edit-dialog
          @save="save(editedElement.limit)"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          <div>{{ props.item.limit }}</div>
          <template v-slot:input>
            <v-text-field
              v-model="editedElement.limit"
              :rules="[rules.number, rules.required]"
              label="Set field max length"
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.screens="props">
        <v-edit-dialog
          @save="saveScreens"
          @cancel="cancel"
          @open="open(props.item)"
          @close="close"
          :key="`${props.item.timestamp}_${keyChanger}`"
          large
        >
          <template v-for="screen in props.item.screens">
            <v-chip
              x-small
              :key="screen"
              @click.prevent.stop="showScreen(screen, props.item.projectID)"
            >
              {{ screen }}
            </v-chip>
          </template>
          <template v-slot:input>
            <v-autocomplete
              :items="screens"
              label="Add keys to screen"
              :filter="filterScreens"
              v-model="editedElement.screens"
              item-value="name"
              item-text="name"
              clearable
              multiple
            >
              <template v-slot:item="data">
                <v-list-item-avatar rounded size="56">
                  <v-img :src="data.item.imageUrl" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attr"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeScreen(data.item.name)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.imageUrl"></v-img>
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { trueClone } from '@/util'

export default {
  props: ['toggleGameMessages'],
  data() {
    return {
      filter: [],
      keyChanger: 0,
      editedElement: { limit: 1 },
      search: '',
      rules: {
        required: value => !!value || 'Required.',
        number: value => !isNaN(value) || 'This should be a number',
        minLength: value => value.length <= 80 || `Max 80 characters`,
      },
      editedLanguages: [],
      selectedScreen: '',
      absoluteHeaders: [
        {
          text: 'State',
          value: 'state',
          filter: value => {
            if (!this.filter.length) return true

            return this.filter.indexOf(value) !== -1
          },
        },
        { text: 'Code', value: 'code' },
        { text: 'Limit', value: 'limit' },
        {
          text: 'Screens',
          value: 'screens',
          filter: value => {
            if (!this.screenNameFilter.length) return true
            return value && value.indexOf(this.screenNameFilter) !== -1
          },
        },
      ],
    }
  },
  watch: {
    editedElement(newVal) {
      console.log('setRulesLimit', newVal.limit)
      this.rules.minLength = value => {
        if (value) {
          return newVal.limit !== 0
            ? value.length <= newVal.limit || `Max ${newVal.limit} characters`
            : "Error: Field length isn't set!"
        } else {
          return true
        }
      }
    },
    selectedLanguages(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.editedLanguages = trueClone(newValue)
      }
    },
    editedLanguages(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.$store.commit('languages/setSelectedLanguages', newValue)
      }
    },
    isCollectionUpdating(newValue) {
      if (!newValue && this.selectedScreen.length) {
        let screen =
          this.screens.find(item => item.name === this.selectedScreen) || {}
        console.log('screen', screen)
        this.$store.commit('gameScreens/setGameScreen', screen)
        this.selectedScreen = ''
      }
    },
  },
  computed: {
    ...mapState({
      projects: state => state.projects.projects,
      project: state => state.projects.project,
      gameMessages: state => state.gameMessages.gameMessages,
      states: state => state.gameMessages.states,
      screens: state => state.gameScreens.screens,
      isUpdating: state => state.gameMessages.isUpdating,
      allLanguages: state => state.languages.allLanguages,
      selectedLanguages: state => state.languages.selectedLanguages,
      screenNameFilter: state => state.gameMessages.screenNameFilter,
      isCollectionUpdating: state => state.gameScreens.isCollectionUpdating,
    }),
    tableHeaders() {
      return this.absoluteHeaders
        .slice(0, 2)
        .concat(this.selectedLanguages)
        .concat(this.absoluteHeaders.slice(2))
    },
  },
  methods: {
    filterScreens(item, queryText) {
      const name = item.name.toLowerCase()
      const description = item.description.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        name.indexOf(searchText) > -1 || description.indexOf(searchText) > -1
      )
    },

    getColor(item) {
      if (item.state === 'New') return 'error'
      if (item.state === 'Has Issue') return 'error'
      if (item.state === 'Updated') return 'warning'
      if (item.state === 'Processed') return 'info'
      else return 'success'
    },
    save(text) {
      if (isNaN(this.editedElement.limit)) {
        this.$store.commit(
          'pushNotification',
          {
            message: 'Incorrect limit value',
            header: 'Caution!',
            color: 'warning',
          },
          {
            root: true,
          }
        )
      } else if (!text || !text.length) {
        this.$store.commit(
          'pushNotification',
          {
            message: 'Please enter a value',
            header: 'Caution!',
            color: 'warning',
          },
          {
            root: true,
          }
        )
      } else if (text.length > this.editedElement.limit) {
        this.$store.commit(
          'pushNotification',
          {
            message: 'Entered text length exceed limit value',
            header: 'Caution!',
            color: 'warning',
          },
          {
            root: true,
          }
        )
      }
      this.$store.dispatch('gameMessages/updateGameMessage', {
        gameMessage: this.editedElement,
      })
      this.keyChanger += 1
      this.editedElement = trueClone({ limit: 1 })
    },
    saveScreens() {
      this.$store.dispatch('gameMessages/updateGameMessage', {
        gameMessage: this.editedElement,
      })
      this.keyChanger += 1
      this.editedElement = trueClone({ limit: 1 })
    },
    showScreen(screenName, projectID) {
      console.log('here')
      this.$store.dispatch('gameScreens/getGameScreens', { projectID })
      this.selectedScreen = screenName
    },

    cancel() {
      this.$store.commit(
        'pushNotification',
        { message: 'Cancelled', header: 'Game Message Update' },
        {
          root: true,
        }
      )
      this.editedElement = trueClone({ limit: 1 })
      this.selectedScreen = ''
    },
    open(item) {
      this.editedElement = trueClone(item)
    },
    close() {
      console.log('Dialog closed')
    },

    removeScreen(item) {
      console.log(item)
      console.log(this.editedElement.screens)
      this.editedElement.screens = this.editedElement.screens.filter(screen => {
        return screen !== item
      })
    },
  },
  mounted() {
    this.editedLanguages = trueClone(this.selectedLanguages)
    this.filter = trueClone(this.states)
  },
}
</script>

<style>
.langs {
  max-width: 24px;
  margin-right: 12px !important;
}
.langs .v-input__append-inner {
  transform: translate(-50%, 0);
}

.game-messages-table {
  table-layout: fixed;
}

.v-data-table-header tr th {
  min-width: 144px;
}

.v-data-table-header tr th:nth-child(1),
.v-data-table-header tr th:nth-child(2),
.v-data-table-header tr th:nth-child(3) {
  min-width: 80px !important;
}

tbody tr td:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px !important;
}

.game-messages-edit-textarea {
  min-width: 300px;
  padding-top: 24px !important;
}
</style>
