import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  primary: '#4caf50',
  secondary: '#cddc39',
  accent: '#03a9f4',
  error: '#f44336',
  warning: '#ff9800',
  info: '#607d8b',
  success: '#8bc34a',
})
