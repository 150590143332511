<template>
  <v-card class="mx-auto" color="gray" width="40vw">
    <v-card-text v-if="!showSuccess">
      <div class="text-h4">Reset Password</div>
      <p>Enter your email to reset your password</p>
      <v-text-field
        v-model.trim="email"
        type="email"
        label="Email"
        required
        :rules="emailRules"
      >
      </v-text-field>
    </v-card-text>
    <v-card-text v-else>
      <div class="text-h4">Success! Check your email for a reset link.</div>
    </v-card-text>
    <v-card-actions v-if="!showSuccess">
      <v-btn text color="info" @click="resetPassword">
        Reset Password
      </v-btn>
      <v-btn text color="error" @click="$emit('close')">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { auth } from '@/plugins/firebase'

export default {
  data() {
    return {
      email: '',
      showSuccess: false,
      errorMsg: '',
      emailRules: [
        v => !!v || 'Email is required',
        v =>
          !!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Incorrect Email',
      ],
    }
  },
  methods: {
    async resetPassword() {
      this.errorMsg = ''

      try {
        await auth.sendPasswordResetEmail(this.email)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    },
  },
}
</script>
